import { useEffect, useMemo, useState } from 'react';

import { useMatch, useNavigate, useSearch } from '@tanstack/react-location';
import isEmpty from 'lodash/isEmpty';
import { useSetRecoilState } from 'recoil';
import ScheduleHeader from 'schedule/components/ScheduleHeader';
import { viewingTypes } from 'schedule/constants/scheduleConstants';
import { getDays, getScheduleRoundsData, setFormStateAndNavigate } from 'schedule/util/courtViewUtil';
import { getFormStateAndViewingTypeFromUrl } from 'schedule/util/scheduleUtil';
import { performAllTournamentSearch } from 'schedule/util/tournamentUtil';
import { HeaderWrapper, SearchWrapper } from 'tournaments/components/_shared/Headers';
import { useTournamentQuery } from 'tournaments/hooks/tournamentsHooks';

import {
  ErrorMessage,
  Flex,
  Grid,
  GridItem,
  LoadingSpinner,
  useBreakpointValue
} from '_shared/designSystem/components';
import ScrollToTopArrow from '_shared/designSystem/components/icon/ScrollToTopArrow';
import { mobileHeaderTextState } from '_shared/globalState/atoms';
import { searchByString } from '_shared/utils/stringUtil';

import MatchCard from './MatchCard';
import ScheduleCourtView from './ScheduleCourtView';
import ScheduleSearch from './ScheduleSearch';

export default function Schedule() {
  const {
    params: { tournamentId }
  } = useMatch();

  const search = useSearch();
  const navigate = useNavigate();

  const [formState, setFormState] = useState({
    round: 'All',
    playerName: '',
    day: null
  });
  const [viewingType, setViewingType] = useState(null);
  const tournament = useTournamentQuery(tournamentId);
  const setMobileHeaderText = useSetRecoilState(mobileHeaderTextState);

  useEffect(() => {
    if (!isEmpty(tournament?.data?.name)) {
      setMobileHeaderText(tournament?.data?.name);
    }
  }, [setMobileHeaderText, tournament?.data?.name]);

  useEffect(() => {
    const { newFormState, viewingType } = getFormStateAndViewingTypeFromUrl(
      search,
      tournament?.data?.comp_status,
      tournament?.data?.active_day
    );
    setFormState(newFormState);
    setViewingType(viewingType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tournament?.data]);

  const listViewSearchResults = useMemo(() => {
    return performAllTournamentSearch(tournament.data?.matches, formState.round, formState.playerName);
  }, [tournament.data, formState.round, formState.playerName]);

  const courtViewSearchResults = useMemo(() => {
    return searchByString(tournament.data?.matches, formState.playerName, 'players');
  }, [tournament.data, formState.playerName]);

  const handleFormStateUpdate = (param, value) => {
    setFormStateAndNavigate(formState, setFormState, param, value, navigate, setViewingType);
  };

  const handleWimbledonDayClick = () => {
    const newFormState = { ...formState };
    newFormState.round = null;
    newFormState.day = tournament?.data?.active_day;
    setViewingType(viewingTypes.COURT_VIEW);
    setFormState({ ...newFormState });
    navigate({
      search: () => ({ ...newFormState })
    });
  };

  if (tournament.error)
    return <ErrorMessage message="Unable to retrieve tournament, please try refreshing the page." />;

  if (tournament.isLoading) return <LoadingSpinner />;

  const data = tournament?.data;

  return (
    <Flex direction="column" gap={4}>
      <HeaderWrapper>
        <ScheduleHeader data={data} />
      </HeaderWrapper>
      <SearchWrapper>
        <ScheduleSearch
          rounds={getScheduleRoundsData(data)}
          days={getDays(data.matches)}
          formState={formState}
          handleFormStateUpdate={handleFormStateUpdate}
          handleWimbledonDayClick={handleWimbledonDayClick}
          viewingType={viewingType}
          tournamentId={tournament?.data?.tournament_id}
        />
      </SearchWrapper>
      {viewingType === viewingTypes.LIST_VIEW ? (
        <ScheduleListView data={listViewSearchResults} />
      ) : (
        <ScheduleCourtView data={courtViewSearchResults} activeDay={formState.day} />
      )}
    </Flex>
  );
}

export const ScheduleListView = ({ data }) => {
  const isMobile = useBreakpointValue({
    base: true,
    md: false
  });
  return (
    <>
      <Grid pt={3} templateColumns="repeat(auto-fit, minmax(340px, 1fr))" gap={8}>
        {data.map((item) => (
          <GridItem key={item.match_id} align={isMobile ? 'center' : 'initial'}>
            <MatchCard data={item} showTournamentInfo={false} />
          </GridItem>
        ))}
      </Grid>
      <ScrollToTopArrow />
    </>
  );
};
