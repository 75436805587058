import PropTypes from 'prop-types';

import { surfaceTypes } from '_shared/constants/matchTypes';
import { Flex, CourtSurface2dFull, Box } from '_shared/designSystem/components';
import CourtArrow from '_shared/designSystem/components/court/CourtArrow';
import { courtArrowTypes } from '_shared/designSystem/components/court/courtConstants';
import colors from '_shared/designSystem/theme/colors';

export default function CourtWithArrow(props) {
  const { surface, arrowType, playType } = props;
  const backgroundColor = `court.background.${surface}`;
  return (
    <Flex bg={backgroundColor}>
      <Flex data-testid="courtWithArrow" w="300px" h="505px" position="relative">
        <Arrows arrowType={arrowType} playType={playType} surface={surface} />
        <CourtSurface2dFull surface={surface} />
      </Flex>
    </Flex>
  );
}

CourtWithArrow.propTypes = {
  surface: PropTypes.oneOf(['hard', 'grass', 'clay']),
  arrowType: PropTypes.oneOf([
    'diagonal_left_to_right',
    'diagonal_right_to_left',
    'straight_left',
    'straight_right',
    'v_shape_from_baseline',
    'a_shape_from_baseline',
    'left_to_middle_from_baseline',
    'right_to_middle_from_baseline',
    'volley_straight_left',
    'volley_straight_right',
    'volley_from_net',
    'drop_shot',
    'baseline_to_center',
    'between_drop_shot_and_center'
  ]),
  playType: PropTypes.oneOf(['all', 'winning', 'losing'])
};

export const Arrows = (props) => {
  const { arrowType, playType, surface } = props;
  let color = colors.grey[300];
  if (playType === 'winning') color = surface === surfaceTypes.GRASS ? colors.success[600] : colors.success[500];
  else if (playType === 'losing') color = surface === surfaceTypes.GRASS ? colors.rose[500] : colors.rose[300];
  switch (arrowType) {
    case courtArrowTypes.STRAIGHT_LEFT:
      return <Arrow color={color} top={100} left={40} angle={0} {...props} />;
    case courtArrowTypes.STRAIGHT_RIGHT:
      return <Arrow color={color} top={100} left={115} angle={0} {...props} />;
    case courtArrowTypes.DIAGONAL_LEFT_TO_RIGHT:
      return <Arrow color={color} top={80} left={140} angle={14} {...props} />;
    case courtArrowTypes.DIAGONAL_RIGHT_TO_LEFT:
      return <Arrow color={color} top={140} left={8} angle={-14} {...props} />;
    case courtArrowTypes.V_SHAPE_FROM_BASELINE:
      return (
        <>
          <Arrow color={color} top={125} left={7} angle={-11} {...props} />
          <Arrow color={color} top={70} left={145} angle={11} {...props} />
        </>
      );
    case courtArrowTypes.A_SHAPE_FROM_BASELINE:
      return (
        <>
          <Arrow color={color} top={100} left={-6} angle={11} {...props} />
          <Arrow color={color} top={100} left={90} angle={-11} {...props} />
        </>
      );
    case courtArrowTypes.LEFT_TO_MIDDLE_FROM_BASELINE:
      return <Arrow color={color} top={80} left={100} angle={9} {...props} />;
    case courtArrowTypes.RIGHT_TO_MIDDLE_FROM_BASELINE:
      return <Arrow color={color} top={120} left={70} angle={-9} {...props} />;
    case courtArrowTypes.VOLLEY_STRAIGHT_LEFT:
      return <Arrow color={color} top={200} left={40} angle={0} arrowType={arrowType} {...props} />;
    case courtArrowTypes.VOLLEY_STRAIGHT_RIGHT:
      return <Arrow color={color} top={200} left={115} angle={0} arrowType={arrowType} {...props} />;
    case courtArrowTypes.VOLLEY_FROM_NET:
      return (
        <>
          <Arrow color={color} top={220} left={-40} angle={-20} arrowType={arrowType} {...props} />
          <Arrow color={color} top={120} left={175} angle={20} arrowType={arrowType} {...props} />
        </>
      );
    case courtArrowTypes.BETWEEN_DROP_SHOT_AND_CENTER:
      return <Arrow color={color} top={180} left={80} angle={0} arrowType={arrowType} {...props} />;
    default:
      return null;
  }
};

const Arrow = (props) => (
  <Box position="absolute" h="500px">
    <CourtArrow {...props} />
  </Box>
);
