import { useEffect, useState } from 'react';

import { useNavigate, useSearch } from '@tanstack/react-location';
import { useHeadToHeadForm } from 'headToHead/hooks/formik';
import {
  useGetHeadToHeadData,
  useGetHeadToHeadInsights,
  useGetHeadToHeadInsightsAverages,
  useGetHeadToHeadStats,
  useGetHeadToHeadStatsAverages
} from 'headToHead/services/headToHeadService';
import { setPlayerGender } from 'headToHead/util/setPlayerGender';
import isEmpty from 'lodash/isEmpty';
import { useGetPlayers } from 'players/services/playersService';
import { BeatLoader } from 'react-spinners';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import NoPermissionAlert from '_shared/components/NoPermissionAlert';
import { userPermissionTypes } from '_shared/constants/user';
import { Box, Center, Divider, ErrorMessage, Flex, LoadingSpinner } from '_shared/designSystem/components';
import { mobileHeaderTextState, userPermissionsState } from '_shared/globalState/atoms';
import { isWimbPortal } from '_shared/utils/environment/currentEnv';
import { checkPermission } from '_shared/utils/permissions';
import { getFromSessionStorage, saveToSessionStorage } from '_shared/utils/sessionStorageUtils';
import { updateUrl } from '_shared/utils/urlUtil';

import {
  ERROR_MESSAGE,
  GENDER_OPTIONS_DEFAULT,
  GENDER_OPTIONS_WIMB,
  VIEW_OPTION_VALUES
} from '../constants/headToHeadConstants';

import BarsPanel from './BarsPanel';
import BarsPanelSkeleton, { MatchupSkeleton } from './BarsPanelSkeleton';
import FilterButtonGroup from './FilterButtonGroup';
import HeadlineWins from './HeadlineWins';
import { MatchCards } from './MatchCards';
import PlayerHeroImagesContainer from './PlayerHeroImagesContainer';
import ProfileSection from './ProfileSection';
import SearchBar from './SearchBar';

const SESSION_STORAGE_KEY = 'headToHeadPlayers';

export const HeadToHead = () => {
  const setMobileHeaderText = useSetRecoilState(mobileHeaderTextState);

  const [view, setView] = useState(VIEW_OPTION_VALUES.FIFTY_TWO_WEEK_AVERAGES);
  const [gender, setGender] = useState(null);
  const [formValuesSet, setFormValuesSet] = useState(false);
  const [reloadHeroImages, setReloadHeroImages] = useState(false);

  const navigate = useNavigate();
  const urlParams = useSearch();
  const { permissions } = useRecoilValue(userPermissionsState);

  const onFieldValueChange = async (name, value) => {
    await formik.setFieldValue(name, value);
    await formik.setFieldTouched(name, true);
    updateUrl(name, value, navigate);
    saveToSessionStorage(SESSION_STORAGE_KEY, name, value);
  };

  const onCompareClick = () => {
    refetchMatchup();
    refetchInsights();
    refetchInsightsAverages();
    refetchStats();
    refetchStatsAverages();
  };

  const { formik } = useHeadToHeadForm();

  const { data: players, isLoading: isLoadingPlayers } = useGetPlayers();
  const {
    data: matchup,
    isFetching: isFetchingMatchup,
    isSuccess: isSuccessMatchup,
    refetch: refetchMatchup,
    error: isErrorMatchup
  } = useGetHeadToHeadData({
    player1: formik.values.player1,
    player2: formik.values.player2
  });

  const { data: insights, refetch: refetchInsights } = useGetHeadToHeadInsights({
    player1: formik.values.player1,
    player2: formik.values.player2
  });

  const {
    data: insightsAverages,
    refetch: refetchInsightsAverages,
    isFetchedAfterMount: isFetchedAfterMountInsightsAverages,
    isFetching: isFetchingInsightsAverages
  } = useGetHeadToHeadInsightsAverages({
    player1: formik.values.player1,
    player2: formik.values.player2
  });

  const { data: stats, refetch: refetchStats } = useGetHeadToHeadStats({
    player1: formik.values.player1,
    player2: formik.values.player2
  });

  const {
    data: statsAverages,
    refetch: refetchStatsAverages,
    isError: statsAveragesError,
    isFetching: isFetchingStatsAverages
  } = useGetHeadToHeadStatsAverages({
    player1: formik.values.player1,
    player2: formik.values.player2
  });

  useEffect(() => {
    if (players && formik.values.player1 && formik.values.player2) {
      setPlayerGender(players, formik.values.player1, formik.values.player2, setGender);
    }
  }, [players, formik.values.player1, formik.values.player2]);

  useEffect(() => {
    const savedPlayers = getFromSessionStorage(SESSION_STORAGE_KEY);
    const hasUrlParams = Object.keys(urlParams).length > 0;

    if (hasUrlParams) {
      Object.entries(urlParams).forEach(([key, value]) => {
        const parsedValue = key === 'player1' || key === 'player2' ? parseInt(value) : value;
        onFieldValueChange(key, parsedValue);
        if (key !== 'showAverages') {
          saveToSessionStorage(SESSION_STORAGE_KEY, key, parsedValue);
        }
      });
    } else if (savedPlayers) {
      formik.setValues(savedPlayers);
    }

    setFormValuesSet(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formValuesSet) {
      if (formik.values.player1 && formik.values.player2) {
        onCompareClick();
      } else if (players?.length > 0) {
        const [player1, player2] = players.filter((player) => player.player_rank !== null && player.player_rank <= 2);
        if (player1 && player2) {
          const updatedValues = {
            player1: player1.player_id,
            player2: player2.player_id
          };
          formik.setValues(updatedValues);

          Object.entries(updatedValues).forEach(([key, value]) => {
            updateUrl(key, value, navigate);
            saveToSessionStorage(SESSION_STORAGE_KEY, key, value);
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValuesSet, players]);

  // only if you are coming from a link, then show the skeleton placeholder for hero images
  useEffect(() => {
    if (Object.keys(urlParams).length > 0) setReloadHeroImages(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFetchedAfterMountInsightsAverages) {
      setReloadHeroImages(false);
    }
  }, [isFetchedAfterMountInsightsAverages]);

  const trackedMatchesCount = matchup?.profile?.tracked_match_count;
  const headToHeadMatchesCount = matchup?.profile?.h2h_matches?.length;

  const { player1, player2 } = matchup?.players || {};

  const player1ThumbnailUrl = isWimbPortal() ? player1?.image_url_wimb : player1?.image_url_atptour;
  const player2ThumbnailUrl = isWimbPortal() ? player2?.image_url_wimb : player2?.image_url_atptour;

  const player1HeroUrl = player1?.hero_image_url_atptour;
  const player2HeroUrl = player2?.hero_image_url_atptour;

  useEffect(() => {
    setMobileHeaderText('Head to Head');
  }, [setMobileHeaderText]);

  if (isErrorMatchup || (isSuccessMatchup && isEmpty(matchup))) return <ErrorMessage message={ERROR_MESSAGE} />;

  if (!checkPermission(userPermissionTypes.HEAD_TO_HEAD, permissions)) {
    return <NoPermissionAlert />;
  }

  return (
    <Box position="relative">
      {isLoadingPlayers ? (
        <Center>
          <BeatLoader size={6} color="grey" />
        </Center>
      ) : (
        <SearchBar
          players={players}
          formik={formik}
          onFieldValueChange={onFieldValueChange}
          onCompareClick={onCompareClick}
          setGender={setGender}
          gender={gender}
          genderOptions={isWimbPortal() ? GENDER_OPTIONS_WIMB : GENDER_OPTIONS_DEFAULT}
          navigate={navigate}
          isLoadingPlayers={isLoadingPlayers}
        />
      )}
      <Divider mt={4} />
      {formik.values.player1 > 0 && formik.values.player2 > 0 && (
        <PlayerHeroImagesContainer
          player1HeroUrl={player1HeroUrl}
          player2HeroUrl={player2HeroUrl}
          player1ThumbnailUrl={player1ThumbnailUrl}
          player2ThumbnailUrl={player2ThumbnailUrl}
          player1LastName={player1?.last_name}
          player2LastName={player2?.last_name}
          gender={gender}
          player1Id={player1?.player_id}
          player2Id={player2?.player_id}
          isFetchingMatchup={isFetchingMatchup}
          reloadHeroImages={reloadHeroImages}
        >
          <Flex direction="column">
            {isFetchingMatchup ? (
              <MatchupSkeleton />
            ) : (
              <Box>
                <HeadlineWins
                  player1FirstName={player1?.first_name}
                  player1LastName={player1?.last_name}
                  player2FirstName={player2?.first_name}
                  player2LastName={player2?.last_name}
                  player1Wins={matchup?.profile?.player1.wins ?? 0}
                  player2Wins={matchup?.profile?.player2.wins ?? 0}
                  player1ThumbnailUrl={player1ThumbnailUrl}
                  player2ThumbnailUrl={player2ThumbnailUrl}
                  player1Id={player1?.player_id}
                  player2Id={player2?.player_id}
                />
                <ProfileSection player1={matchup?.profile?.player1} player2={matchup?.profile?.player2} />
              </Box>
            )}
            <Box mt={9}>
              <FilterButtonGroup
                trackedMatchesCount={trackedMatchesCount}
                headToHeadMatchesCount={headToHeadMatchesCount}
                player1Name={player1?.last_name}
                player2Name={player2?.last_name}
                player1Matches={matchup?.profile?.player1?.player_average_matches}
                player2Matches={matchup?.profile?.player2?.player_average_matches}
                setView={setView}
                view={view}
                showAverages={formik.values.showAverages}
                legendFieldsToShow
              />
            </Box>
            <Box mb={{ base: 4, sm: 10 }}>
              {isFetchingInsightsAverages ? (
                <Box mt={8}>
                  <BarsPanelSkeleton />
                </Box>
              ) : (
                <BarsPanel
                  dataNoAverages={insights}
                  dataWithAverages={insightsAverages}
                  showAverages={formik.values.showAverages}
                  view={view}
                  player1Name={player1?.last_name}
                  player2Name={player2?.last_name}
                />
              )}
            </Box>
            {isFetchingStatsAverages ? (
              <Box mt={8}>
                <BarsPanelSkeleton />
              </Box>
            ) : (
              !statsAveragesError && (
                <BarsPanel
                  dataNoAverages={stats}
                  dataWithAverages={statsAverages}
                  showAverages={formik.values.showAverages}
                  view={view}
                  player1Name={player1?.last_name}
                  player2Name={player2?.last_name}
                />
              )
            )}
            {isFetchingMatchup ? (
              <LoadingSpinner />
            ) : (
              <Box my="40px">
                <MatchCards trackedMatches={matchup?.profile?.h2h_matches} />
              </Box>
            )}
          </Flex>
        </PlayerHeroImagesContainer>
      )}
    </Box>
  );
};
