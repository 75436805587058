import { displayScore } from 'match/utils/transformations';
import { formatBarChartData, formatPieChartData, getDetailedViewLegend } from 'match/utils/winningAndLosingPlaysUtil';
import PropTypes from 'prop-types';
import CourtWithArrow from 'scout/components/winning_and_losing_plays/CourtWithArrow';
import { getArrowType } from 'scout/util/scoutWinningAndLosingPlaysUtil';

import customSitesConfig from '_shared/config/customSitesConfig';
import { Flex, Text, Box, PieChart, Legend, Icon, Divider } from '_shared/designSystem/components';
import BarChart from '_shared/designSystem/components/charts/barChart/BarChart';
import { capitaliseString } from '_shared/utils/stringUtil';

import PanelContainer from '../performance/_shared/PanelContainer';
import { leftColumnContentWidth } from '../performance/performanceMargins';

import CourtTopStats from './CourtTopStats';
import CourtWithShots from './CourtWithShots';

export default function DetailedView(props) {
  const { data, closeDetailedView, surface, isScout, handedness } = props;
  const isAllPlays = data?.play_type === 'all';
  const barChartData = formatBarChartData(data?.tactics);
  const pieChartData = formatPieChartData(data?.tactics);

  const numberPlays = isAllPlays
    ? ''
    : ' ' +
      displayScore(
        data?.total_scores?.[`${data?.play_type}`]?.score,
        data?.total_scores?.[`${data?.play_type}`]?.score_type
      );
  const heading = `${capitaliseString(data?.id)} -${numberPlays} ${capitaliseString(data?.play_type)} Plays`;

  return (
    <Box maxW="1250px">
      <Flex justify="space-between">
        <Box w="50px" />
        <Box>
          <Text fontSize="md" fontWeight="semibold" color="secondary.800" align="center" mb={1}>
            {heading}
          </Text>
          {isAllPlays && <CourtTopStats data={data} hideHeading={true} />}
        </Box>
        <Box onClick={closeDetailedView} cursor="pointer">
          <Icon name="cross" width="50px" height="50px" color="grey.400" />
        </Box>
      </Flex>
      <Flex justify="center">
        <Flex mt={4} flexWrap="wrap" gap="40px" justify="center">
          <Flex direction="column" align={{ base: 'center', lg: 'flex-end' }}>
            <Box>
              <Flex direction="column" gap={10}>
                {isScout ? (
                  <Box w="300px">
                    <CourtWithArrow
                      surface={customSitesConfig.defaultScoutCourt}
                      arrowType={getArrowType(data?.id, handedness)}
                      playType={data?.play_type}
                    />
                  </Box>
                ) : (
                  <Box w={['350px', '350px', '350px', '320px', '400px', '500px']}>
                    <CourtWithShots surface={surface} shots={data?.shots} />
                  </Box>
                )}
              </Flex>
            </Box>
            <Divider display={{ base: 'flex', lg: 'none' }} mt={8} />
          </Flex>
          <Flex direction="column" gap={10} alignItems={{ base: 'center', lg: 'stretch' }}>
            {!isAllPlays && (
              <Flex justify="center">
                <Legend fields={getDetailedViewLegend(data?.tactics)} />
              </Flex>
            )}
            <PanelContainer contentWidth={leftColumnContentWidth}>
              <Box w={['350px', '400px', '350px', '350px', '400px', '400px']}>
                <BarChart data={barChartData.data} categories={barChartData.categories} colors={pieChartData.colors} />
              </Box>
            </PanelContainer>
            {!isAllPlays && (
              <PanelContainer contentWidth={leftColumnContentWidth}>
                <Box w={['250px', '250px', '250px', '200px', '275px', '300px']}>
                  <PieChart data={pieChartData.data} labels={pieChartData.categories} colors={pieChartData.colors} />
                </Box>
              </PanelContainer>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}

DetailedView.propTypes = {
  data: PropTypes.object.isRequired,
  closeDetailedView: PropTypes.func.isRequired,
  surface: PropTypes.string.isRequired,
  isScout: PropTypes.bool,
  handedness: PropTypes.string
};
