import { isEmptyArray } from 'formik';
import PropTypes from 'prop-types';

import { Flex, Box } from '_shared/designSystem/components';

import CourtTopStats from './CourtTopStats';
import CourtWithShots from './CourtWithShots';

export default function CourtWithTopStats(props) {
  const { data, openDetailedView, surface } = props;
  if (isEmptyArray(data)) return null;
  return (
    <Flex direction="column" gap={4} cursor="pointer" onClick={() => openDetailedView(data)}>
      <Box w="100%">
        <CourtTopStats data={data} />
      </Box>
      <CourtWithShots surface={surface} shots={data?.shots} />
    </Flex>
  );
}

CourtWithTopStats.propTypes = {
  data: PropTypes.object,
  openDetailedView: PropTypes.func,
  surface: PropTypes.string
};
