import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import customSitesConfig from '_shared/config/customSitesConfig';
import { Box, Alert, Table, Thead, Tr, Tbody, Td, Th } from '_shared/designSystem/components';
export default function PlayersListTable(props) {
  const { columnHeadings, data, openScoutPage } = props;

  if (isEmpty(data) || isEmpty(columnHeadings))
    return (
      <Box py={4}>
        <Alert status="info" message="No players to show"></Alert>
      </Box>
    );

  return (
    <Box>
      <Table mt={3}>
        {!customSitesConfig.playersListNameOnly && (
          <Thead>
            <Tr>
              {columnHeadings.map((item, index) => (
                <Th key={index}>{item}</Th>
              ))}
            </Tr>
          </Thead>
        )}
        <Tbody>
          {data?.map((item) => {
            const playerName = customSitesConfig.playersListSurnameFirst
              ? `${item.last_name}, ${item.first_name}`
              : `${item.first_name} ${item.last_name}`;
            return (
              <Tr
                _hover={{ bg: 'primary.25' }}
                cursor="pointer"
                key={item.player_id}
                onClick={() => openScoutPage(item.player_id)}
              >
                {!customSitesConfig.playersListNameOnly && (
                  <Td w="100px" whiteSpace="nowrap">
                    {item.player_rank}
                  </Td>
                )}
                <Td>{playerName}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
}

PlayersListTable.propTypes = {
  data: PropTypes.array,
  columnHeadings: PropTypes.array,
  headerTop: PropTypes.object,
  openScoutPage: PropTypes.func,
  navigate: PropTypes.func,
  location: PropTypes.object
};
