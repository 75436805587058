import { BREAKDOWN_METRICS_TYPES } from 'match/_shared/matchConstants';
import BreakdownMetrics from 'match/components/performance/breakdownMetrics/BreakdownMetrics';
import PageHeading from 'matchReport/components/_shared/PageHeading';
import { useMatchReportPerformanceQuery } from 'matchReport/hooks/matchReportHooks';

import { Box, Flex } from '_shared/designSystem/components';

const ServeEffectiveness = ({ performanceData, matchId }) => {
  const graphData = useMatchReportPerformanceQuery(matchId);
  if (graphData.error) throw new Error('Unable to retrieve graph data');
  if (graphData.isLoading) return null;
  if (graphData.data) {
    return (
      <Box>
        <PageHeading>Serve Effectiveness</PageHeading>
        <Flex gap="70px">
          <Box pt={5}>
            <Flex direction="column" gap={20} align="center">
              <Box w="450px">
                <Box>
                  <BreakdownMetrics
                    graphicType={BREAKDOWN_METRICS_TYPES.SERVE_EFFECTIVENESS}
                    dataNoAverages={performanceData?.performance?.breakdown_metrics?.serve_effectiveness}
                    dataWithAverages={performanceData?.performance?.breakdown_metrics?.serve_effectiveness}
                    isMatch={true}
                    players={performanceData?.players}
                    showAverages={false}
                    averagesLoading={false}
                    serveType={1}
                    isReport
                  />
                </Box>
              </Box>
            </Flex>
          </Box>
          <Box>
            <Box pt={5}>
              <BreakdownMetrics
                graphicType={BREAKDOWN_METRICS_TYPES.SERVE_EFFECTIVENESS}
                dataNoAverages={performanceData?.performance?.breakdown_metrics?.serve_effectiveness}
                dataWithAverages={performanceData?.performance?.breakdown_metrics?.serve_effectiveness}
                isMatch={true}
                players={performanceData?.players}
                showAverages={false}
                averagesLoading={false}
                serveType={2}
                isReport
              />
            </Box>
          </Box>
        </Flex>
      </Box>
    );
  }
  return null;
};

export default ServeEffectiveness;
