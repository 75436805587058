import { useState } from 'react';

import { useMatch } from '@tanstack/react-location';
import {
  useHeaderQuery,
  usePatternsOfPlayNoAveragesQuery,
  usePatternsOfPlayWithAveragesQuery
} from 'match/hooks/matchHooks';
import PatternsOfPlay from 'scout/components/patterns_of_play/PatternsOfPlay';
import { getPatternsOfPlayParams } from 'scout/util/scoutPatternsOfPlayUtil';

export default function MatchPatternsOfPlay() {
  const {
    params: { matchId }
  } = useMatch();

  const [patternsOfPlayParams, setPatternsOfPlayParams] = useState(null);

  const header = useHeaderQuery(matchId);

  const { data: dataNoAverages, isLoading: isLoadingNoAverages } = usePatternsOfPlayNoAveragesQuery(
    matchId,
    patternsOfPlayParams
  );

  const { data: dataWithAverages, isLoading: isLoadingWithAverages } = usePatternsOfPlayWithAveragesQuery(
    matchId,
    patternsOfPlayParams
  );

  function updateParams(pattern, filterValues) {
    const patternsOfPlayParams = getPatternsOfPlayParams(pattern, filterValues);
    setPatternsOfPlayParams(patternsOfPlayParams);
  }

  return (
    <PatternsOfPlay
      dataNoAverages={dataNoAverages}
      dataWithAverages={dataWithAverages}
      averagesLoading={isLoadingWithAverages}
      isLoadingNoAverages={isLoadingNoAverages}
      updateParams={updateParams}
      hasPlayerFilter={true}
      playersData={header?.data}
      surface={header?.data?.surface}
    />
  );
}
