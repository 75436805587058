import { useState, useEffect } from 'react';

import { Box, Flex, Text, Table, Tbody, Td, Th, Thead, Tr, Spinner, Alert } from '@ellipsedata/tennis';
import { useSearch, useNavigate } from '@tanstack/react-location';
import { leaderboardService, useGetLeaderboardFormOptions } from 'leaderboard/services/leaderboardService';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import { useMutation } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { useRecoilValue } from 'recoil';

import NoPermissionAlert from '_shared/components/NoPermissionAlert';
import { bannerAlertType } from '_shared/constants/alertTypes';
import { userPermissionTypes } from '_shared/constants/user';
import { userPermissionsState } from '_shared/globalState/atoms';
import { mobileHeaderTextState } from '_shared/globalState/atoms';
import useFeature from '_shared/utils/hooks/useFeature';
import { navigateTo } from '_shared/utils/navigationUtils';
import { checkPermission } from '_shared/utils/permissions';

import SearchForm from './SearchForm';

export default function Leaderboard() {
  const [resultsData, setResultsData] = useState(null);
  const [urlParamsErrors, setUrlParamsErrors] = useState([]);
  const [searchResultsErrorMessage, setSearchResultsErrorMessage] = useState('');
  const setMatchReportText = useSetRecoilState(mobileHeaderTextState);

  const leaderboardEnabled = useFeature('leaderboard');
  const urlParams = useSearch();
  const navigate = useNavigate();

  useEffect(() => {
    setMatchReportText('Leaderboard');
  }, [setMatchReportText]);

  const { isLoading, mutate } = useMutation((queryParams) => leaderboardService.getSearchResults({ queryParams }), {
    onSuccess: (data) => {
      setResultsData(data);
      setSearchResultsErrorMessage('');
    },
    onError: (error) => {
      if (error.response && error.response.status === 400) {
        const specificMessage = error.response.data.message;
        setSearchResultsErrorMessage(specificMessage);
        setResultsData(null);
      } else {
        const unexpectedMessage = 'Unable to retrieve leaderboard results. Please try again';
        setSearchResultsErrorMessage(unexpectedMessage);
        setResultsData(null);
      }
    }
  });

  const addParamToUrl = (field_name, field_value) => {
    const newObject = {};
    newObject[`${field_name}`] = field_value;
    navigate({ search: (old) => ({ ...old, ...newObject }) });
  };

  const {
    isLoading: formOptionsIsLoading,
    error: formOptionsError,
    data: formOptionsData
  } = useGetLeaderboardFormOptions();

  const { permissions } = useRecoilValue(userPermissionsState);

  if (!checkPermission(userPermissionTypes.LEADERBOARD, permissions)) {
    return <NoPermissionAlert />;
  }

  if (formOptionsError) throw new Error(`Unable to retrieve leaderboard form`);

  if (!leaderboardEnabled) return null;

  return (
    <Box>
      <Box borderBottomWidth="1px" borderColor="primary.500" pb={3}>
        <Text
          display={{ base: 'none', md: 'initial' }}
          fontSize="3xl"
          fontWeight="medium"
          color="secondary.800"
          mt={0}
          textAlign={{ base: 'center', md: 'initial' }}
        >
          Leaderboard
        </Text>
        {!isEmpty(urlParamsErrors) && (
          <Flex direction="column" gap={2} pt={3}>
            {urlParamsErrors.map((item) => (
              <Alert
                key={`${item.displayName}-${item.incorrectValue}`}
                status={bannerAlertType.WARNING}
                message={`${startCase(toLower(item.incorrectValue))} is not a valid option for ${startCase(
                  toLower(item.fieldName)
                )}. Using ${startCase(toLower(item.defaultValue))} instead`}
              />
            ))}
          </Flex>
        )}
        {formOptionsIsLoading ? (
          <Flex pt={30} justify="center">
            <Spinner color="primary.500" />
          </Flex>
        ) : (
          <Box mt={2}>
            <SearchForm
              formOptions={formOptionsData}
              urlParams={urlParams}
              mutate={mutate}
              addParamToUrl={addParamToUrl}
              setUrlParamsErrors={setUrlParamsErrors}
            />
          </Box>
        )}
      </Box>
      {isLoading && (
        <Flex mt="100px" justify="center">
          <Spinner color="primary.500" />
        </Flex>
      )}
      {searchResultsErrorMessage && !isLoading && (
        <Alert status={bannerAlertType.INFO} message={searchResultsErrorMessage} />
      )}
      {resultsData && !isLoading && (
        <Box overflowX={{ base: 'auto', md: 'initial' }} mt={4}>
          <Table>
            <Thead>
              <Tr>
                <Th>#</Th>
                {resultsData?.column_names?.map((item) => (
                  <Th key={item.field}>{item.display_name}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {resultsData?.results?.map((item, index) => {
                return (
                  <Tr
                    key={item.player_id}
                    onClick={() => {
                      navigateTo({
                        url: `/scout/${item.player_id}`,
                        navigate
                      });
                    }}
                    _hover={{
                      color: 'primary.500',
                      cursor: 'pointer',
                      bg: 'primary.25'
                    }}
                  >
                    <Td>{index + 1}</Td>
                    {resultsData?.column_names?.map((column, index) => (
                      <Td key={index}>{item[`${column.field}`]}</Td>
                    ))}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      )}
    </Box>
  );
}
