export const userPermissionTypes = {
  TOURNAMENTS: 'tournaments',
  PERFORMANCE: 'performance',
  BALANCE_OF_POWER: 'balance_of_power',
  MOMENTUM: 'momentum',
  BASELINE_NET_BATTLES: 'baseline_net_battles',
  SHOT_QUALITY: 'shot_quality',
  ATTACK_DEFENCE: 'attack_defence',
  SERVE_RETURN_GAME_PERFORMANCE: 'serve_return_game_performance',
  PERFORMANCE_RATING: 'performance_rating',
  PERFORMANCE_GRAPHS: 'performance_graphs',
  SHOT_VARIATION: 'shot_variation',
  SERVE_EFFECTIVENESS: 'serve_effectiveness',
  DATA_COMPLETENESS: 'data_completeness',
  DATA_CONFIDENCE: 'data_confidence',
  MATCH_STATISTICS: 'match_statistics',
  MATCH_STATISTICS_SERVE: 'match_statistics_serve',
  MATCH_STATISTICS_RETURN: 'match_statistics_return',
  MATCH_STATISTICS_WINNERS_AND_UNFORCED_ERRORS: 'match_statistics_winners_and_unforced_errors',
  MATCH_STATISTICS_RALLY: 'match_statistics_rally',
  MATCH_REPORT: 'match_report',
  CSV_REPORT: 'csv_report',
  COURT_GRAPHICS: 'court_graphics',
  LEADERBOARD: 'leaderboard',
  HEAD_TO_HEAD: 'head_to_head',
  WINNING_AND_LOSING_PLAYS: 'winning_and_losing_plays',
  PATTERNS_OF_PLAY: 'patterns_of_play',
  VIDEO_ANALYSIS: 'video_analysis',
  MATCH_CENTRE_VIDEO_TAB: 'match_centre_video_tab',
  SCOUT_AREA: 'scout_area',
  SCOUT_REPORT: 'scout_report',
  MATCH_IN_PROGRESS: 'match_in_progress',
  UPDATE_DATA_QUALITY_FLAG: 'update_data_quality_flag',
  DATA_QUALITY_BANNER_SHOW_DATA: 'data_quality_banner_show_data'
};
