import { SinglePlay } from 'match/components/winning-and-losing-plays/SinglePlay';
import {
  MatchNoAveragesSkeleton,
  MatchWithAveragesSkeleton
} from 'match/components/winning-and-losing-plays/SinglePlaySkeletons';
import PropTypes from 'prop-types';

import { SimpleGrid, Box } from '_shared/designSystem/components';

// This component is a grid of SinglePlay components
// It will be used for all potential data views for winning and losing plays in the Match Page and the Scout Page
export const SinglePlayGrid = ({
  playDataNoAverages = [],
  playDataWithAverages = [],
  showAverages,
  isLoading,
  averagesLoading,
  surface,
  volumeType,
  selectedPlayType,
  isMatchPage,
  handleSinglePlayClick,
  playerData
}) => {
  if (showAverages && averagesLoading)
    return (
      <LayoutGrid>
        {[...Array(9)].map((_, index) => (
          <MatchWithAveragesSkeleton key={index} isMatchPage={isMatchPage} />
        ))}
      </LayoutGrid>
    );
  else if (isLoading)
    return (
      <LayoutGrid>
        {[...Array(9)].map((_, index) => (
          <MatchNoAveragesSkeleton key={index} isMatchPage={isMatchPage} />
        ))}
      </LayoutGrid>
    );

  return (
    <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 2, xl: 3 }} spacing={8}>
      {playDataNoAverages.map((play) => {
        const dataWithAverages = playDataWithAverages.find((data) => data.play_id === play.play_id) || {};
        return (
          <Box
            key={play.play_id}
            aria-label="single-play"
            minW="350px"
            onClick={() => handleSinglePlayClick(play.play_id)}
            cursor="pointer"
            borderBottom="solid 1px"
            borderColor="grey.300"
          >
            <SinglePlay
              dataNoAverages={play}
              dataWithAverages={dataWithAverages}
              showAverages={showAverages}
              isLoading={isLoading}
              isLoadingAverages={averagesLoading}
              surface={surface}
              volumeType={volumeType}
              isMatchPage={isMatchPage}
              selectedPlayType={selectedPlayType}
              playerData={playerData}
            />
          </Box>
        );
      })}
    </SimpleGrid>
  );
};

SinglePlayGrid.propTypes = {
  playDataNoAverages: PropTypes.arrayOf(PropTypes.object),
  playDataWithAverages: PropTypes.arrayOf(PropTypes.object),
  showAverages: PropTypes.bool,
  isLoading: PropTypes.bool,
  averagesLoading: PropTypes.bool,
  surface: PropTypes.string,
  volumeType: PropTypes.oneOf(['volume', 'effectiveness']),
  isMatchPage: PropTypes.bool,
  handleSinglePlayClick: PropTypes.func
};

SinglePlayGrid.defaultProps = {
  playDataNoAverages: [],
  playDataWithAverages: [],
  showAverages: false,
  isLoading: false,
  averagesLoading: false,
  surface: '',
  volumeType: 'volume',
  isMatchPage: true
};

export default SinglePlayGrid;

const LayoutGrid = ({ children }) => (
  <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 2, xl: 3 }} spacing={8}>
    {children}
  </SimpleGrid>
);
