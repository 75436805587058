import isEmpty from 'lodash/isEmpty';
import CourtWithTopStats from 'match/components/winning-and-losing-plays/CourtWithTopStats';
import { useMatchReportWinningAndLosingPlaysQuery } from 'matchReport/hooks/matchReportHooks';
import PropTypes from 'prop-types';

import { Box, Flex, Text } from '_shared/designSystem/components';
import { capitaliseString } from '_shared/utils/stringUtil';

import OutcomesTable from './OutcomesTable';

const WinningAndLosingPlays = ({ matchId, player1Name, player2Name, surface, playType }) => {
  const queryResultPlayer1 = useMatchReportWinningAndLosingPlaysQuery(matchId, { player: 'player1', playType });
  const queryResultPlayer2 = useMatchReportWinningAndLosingPlaysQuery(matchId, { player: 'player2', playType });

  if (queryResultPlayer1.error) throw new Error('Unable to retrieve winning and losing plays data');
  if (queryResultPlayer1.isLoading) return null;
  if (!queryResultPlayer1.data) return null;
  return (
    <Box>
      <Box>
        <Box h="50px">
          <Text fontSize="xl" color="grey.700" fontWeight="bold" textAlign="center">
            {`${player1Name} ${capitaliseString(playType)} Plays`}
          </Text>
        </Box>
        <Box>
          <RowOfPlays data={queryResultPlayer1.data} surface={surface} playType={playType} />
        </Box>
      </Box>
      <Box h={playType === 'all' ? '125px' : '50px'} />
      <Box>
        <Box h="50px">
          <Text fontSize="xl" color="grey.700" fontWeight="bold" textAlign="center">
            {`${player2Name} ${capitaliseString(playType)} Plays`}
          </Text>
        </Box>
        <Box>
          <RowOfPlays data={queryResultPlayer2.data} surface={surface} playType={playType} />
        </Box>
      </Box>
    </Box>
  );
};

WinningAndLosingPlays.propTypes = {
  matchId: PropTypes.string.isRequired,
  player1Name: PropTypes.string.isRequired,
  player2Name: PropTypes.string.isRequired,
  surface: PropTypes.string.isRequired,
  playType: PropTypes.string.isRequired
};

const RowOfPlays = ({ data, surface, playType }) => (
  <Flex gap={6} justify="center" px={10}>
    {!isEmpty(data) &&
      data.slice(0, 3).map((item, index) => (
        <Box w="225px" key={index}>
          <CourtWithTopStats data={item} openDetailedView={null} surface={surface} />
          {(playType === 'winning' || playType === 'losing') && (
            <OutcomesTable data={item?.tactics} playType={playType} />
          )}
        </Box>
      ))}
  </Flex>
);

export default WinningAndLosingPlays;
