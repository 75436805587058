import { BARS_OPACITY } from 'match/constants/winningAndLosingPlaysConstants';
import { determineColor, createTransformedType } from 'match/utils/winningAndLosingPlaysUtil';
import PropTypes from 'prop-types';

import { Box, Flex, Text, Icon, LoadingSpinner } from '_shared/designSystem/components';
import BarGraphic from '_shared/designSystem/components/charts/barGraphic/BarGraphic';
import { isArrayOfArrays } from '_shared/utils/dataTypeUtils';
import { capitaliseString } from '_shared/utils/stringUtil';

import SinglePlay from './SinglePlay';
import { dataNoAveragesObjectType, dataWithAveragesObjectType } from './SinglePlayTypes';

export const determineDetailedViewBarColor = (label) => {
  const colorMap = {
    winner: 'success.300',
    opponent_winner: 'success.300',
    error: 'error.300',
    opponent_error: 'error.300',
    in_attack: 'warning.200',
    in_defence: 'warning.200'
  };

  return colorMap[label] || 'primary.300';
};

export const mapPlayData = (plays) =>
  plays?.map((play) => ({
    label: capitaliseString(play?.id),
    frequency: play?.frequency,
    score: play?.score,
    score_type: play?.score_type,
    color: determineDetailedViewBarColor(play?.id)
  }));

const BarSection = ({ title, data, showAverages }) => {
  const isDataArrayOfArrays = isArrayOfArrays(data);

  return (
    <Box height="100%" minW="300px" maxW="400px" borderRadius="md" boxShadow="md" p={8}>
      <Flex direction="column" alignItems="center" height="100%">
        <Text fontSize="lg" fontWeight="semibold" mb={6}>
          {title}
        </Text>
        {isDataArrayOfArrays ? (
          data.map((item, index) => (
            <Box key={index} width="100%" mt={4}>
              <Text fontSize="md" fontWeight="semibold" mb={2}>
                {item[0]?.label}
              </Text>
              <BarGraphic
                data={item}
                showLabels={false}
                barHeight={showAverages ? 'md' : 'xl'}
                aria-label="single-bar"
                opacity={BARS_OPACITY}
              />
            </Box>
          ))
        ) : (
          <BarGraphic data={data} showLabels={true} barHeight="xl" aria-label="single-bar" opacity={BARS_OPACITY} />
        )}
      </Flex>
    </Box>
  );
};

const processPlaysWithAverages = (playsNoAverages, playsWithAverages) =>
  playsNoAverages.map((type) => {
    const matchingType = playsWithAverages.find((outcome) => capitaliseString(outcome.id) === type.label);

    if (!matchingType) return [type];

    const { frequency, player_average, tour_average, score_type } = matchingType;
    const playerAvgColor = determineColor({ label: 'Player Avg' });
    const tourAvgColor = determineColor({ label: 'Tour Avg' });

    return [
      type,
      createTransformedType('Player Avg', frequency, player_average, score_type, playerAvgColor),
      createTransformedType('Tour Avg', frequency, tour_average, score_type, tourAvgColor)
    ];
  });

const SinglePlayDetailedView = ({
  surface,
  dataNoAverages,
  dataWithAverages,
  isLoading,
  isLoadingAverages,
  showAverages,
  volumeType,
  isMatchPage,
  handleClose
}) => {
  const { tactics = [], outcomes: { winning = [], losing = [] } = {} } = dataNoAverages;
  const { outcomes: { winning: winningWithAverages = [], losing: losingWithAverages = [] } = {} } = dataWithAverages;

  const playDirectionNoAverages = mapPlayData(tactics);
  const winningPlaysNoAverages = mapPlayData(winning);
  const losingPlaysNoAverages = mapPlayData(losing);

  const winningPlaysWithAverages = processPlaysWithAverages(winningPlaysNoAverages, winningWithAverages);
  const losingPlaysWithAverages = processPlaysWithAverages(losingPlaysNoAverages, losingWithAverages);

  if (isLoadingAverages || isLoading) return <LoadingSpinner />;

  return (
    <Flex gap={8}>
      <Box w="40%" maxW="450px">
        <SinglePlay
          surface={surface}
          dataNoAverages={dataNoAverages}
          dataWithAverages={dataWithAverages}
          showAverages={showAverages}
          isLoading={isLoading}
          isLoadingAverages={isLoadingAverages}
          volumeType={volumeType}
          isMatchPage={isMatchPage}
          isDetailed={true}
        />
      </Box>
      <Box w="60%" p={0}>
        {tactics.length > 0 && (
          <Box mb={12}>
            <BarSection title="Play Direction" data={playDirectionNoAverages} showAverages={showAverages} />
          </Box>
        )}
        <Flex justifyContent="space-between" flexWrap="wrap" rowGap={10} gap={10}>
          <Box flex={1}>
            <BarSection
              title="Winning Plays"
              data={showAverages ? winningPlaysWithAverages : winningPlaysNoAverages}
              showAverages={showAverages}
            />
          </Box>
          <Box flex={1}>
            <BarSection
              title="Losing Plays"
              data={showAverages ? losingPlaysWithAverages : losingPlaysNoAverages}
              showAverages={showAverages}
            />
          </Box>
        </Flex>
      </Box>
      <Box onClick={handleClose} cursor="pointer">
        <Icon name="cross" width="50px" height="50px" color="grey.400" />
      </Box>
    </Flex>
  );
};

SinglePlayDetailedView.propTypes = {
  surface: PropTypes.string,
  dataNoAverages: dataNoAveragesObjectType,
  dataWithAverages: dataWithAveragesObjectType,
  isLoading: PropTypes.bool,
  isLoadingAverages: PropTypes.bool,
  showAverages: PropTypes.bool,
  volumeType: PropTypes.oneOf(['volume', 'effectiveness']),
  isMatchPage: PropTypes.bool.isRequired,
  handleClose: PropTypes.func
};

SinglePlayDetailedView.defaultProps = {
  surface: '',
  dataNoAverages: {},
  dataWithAverages: {},
  isLoading: false,
  isLoadingAverages: false,
  showAverages: false,
  volumeType: 'volume',
  isMatchPage: true,
  handleClose: () => {}
};

export default SinglePlayDetailedView;
