import isEmpty from 'lodash/isEmpty';
import CourtTopStats from 'match/components/winning-and-losing-plays/CourtTopStats';
import PageHeading from 'matchReport/components/_shared/PageHeading';
import OutcomesTable from 'matchReport/components/winningAndLosingPlays/OutcomesTable';
import PropTypes from 'prop-types';
import CourtWithArrow from 'scout/components/winning_and_losing_plays/CourtWithArrow';
import { getArrowType } from 'scout/util/scoutWinningAndLosingPlaysUtil';
import PageLayout from 'scoutReport/components/PageLayout';

import { Box, Flex } from '_shared/designSystem/components';

export default function WinningAndLosingPlaysPage(props) {
  const { playerName, heading, data, handedness, playType, surface } = props;

  return (
    <PageLayout playerName={playerName}>
      <PageHeading>{heading}</PageHeading>
      <Flex gap={6} justify="center" px={10} flexWrap="wrap" rowGap="40px">
        {!isEmpty(data) &&
          data.slice(0, 6).map((item, index) => {
            const reportLastRow = playType === 'losing' && [3, 4, 5].includes(index);
            if (reportLastRow) return null; // still issues with the last row, so hiding it temporarily
            return (
              <Box key={index}>
                <CourtWithArrowAndData
                  item={item}
                  handedness={handedness}
                  playType={playType}
                  surface={surface}
                  reportLastRow={reportLastRow}
                />
                <Flex justify="center">
                  <Box w="220px">
                    <OutcomesTable data={item?.tactics} playType={playType} />
                  </Box>
                </Flex>
              </Box>
            );
          })}
      </Flex>
    </PageLayout>
  );
}

WinningAndLosingPlaysPage.propTypes = {
  playerName: PropTypes.string,
  heading: PropTypes.string,
  surface: PropTypes.string,
  data: PropTypes.array,
  handedness: PropTypes.string,
  playType: PropTypes.string
};

const CourtWithArrowAndData = ({ item, handedness, playType, surface }) => {
  const arrowType = getArrowType(item.id, handedness);
  return (
    <Flex key={item.id} direction="column" gap={4} cursor="pointer" onClick={null}>
      <Box w="100%">
        <CourtTopStats data={item} />
      </Box>
      <Box mt="-80px" mb="-60px">
        <Box transform="scale(0.7, 0.7)">
          <CourtWithArrow surface={surface} arrowType={arrowType} playType={playType} />
        </Box>
      </Box>
    </Flex>
  );
};
