import atptourColors from '_customSites/atptour/theme/atptourColors';

export const atptourPieChartColors = [
  atptourColors.illustration.greenLight,
  atptourColors.illustration.orangeLight,
  atptourColors.illustration.blueLight,
  atptourColors.illustration.greenGrey,
  atptourColors.electricYellow
];

export const atptourPieChartLegend = [
  {
    label: 'Serve',
    color: 'illustration.greenLight'
  },
  {
    label: 'Return',
    color: 'illustration.orangeLight'
  },
  {
    label: 'Forehand',
    color: 'illustration.blueLight'
  },
  {
    label: 'Backhand',
    color: 'illustration.greenGrey'
  },
  {
    label: 'Volley',
    color: 'electricYellow'
  }
];

export const atptourPerformanceTrackerColors = {
  player1Line: atptourColors.primary[500],
  player2Line: atptourColors.grey[800],
  player1Fill: atptourColors.primary[200],
  player2Fill: atptourColors.grey[200],
  tourAverageLine: atptourColors.tourAverage
};

export const atptourStackedBarColors = {
  serveEffectiveness: [
    { bg: 'navy', text: 'white' },
    { bg: 'primary.500', text: 'white' },
    { bg: 'primary.300', text: 'white' },
    { bg: 'grey.200', text: 'secondary.800' },
    { bg: 'rose.50', text: 'secondary.800' },
    { bg: 'pink.100', text: 'secondary.800' },
    { bg: 'error.200', text: 'secondary.800' }
  ],
  shotVariation: [
    { bg: 'grey.200', text: 'secondary.800' },
    { bg: 'primary.25', text: 'secondary.800' },
    { bg: 'primary.100', text: 'secondary.800' },
    { bg: 'primary.200', text: 'grey.500' },
    { bg: 'primary.300', text: 'grey.300' },
    { bg: 'primary.500', text: 'grey.300' }
  ]
};
