import { useEffect, useState } from 'react';

import { useNavigate, useSearch } from '@tanstack/react-location';
import isEmpty from 'lodash/isEmpty';
import CourtTopStats from 'match/components/winning-and-losing-plays/CourtTopStats';
import DetailedView from 'match/components/winning-and-losing-plays/DetailedView';
import { getItemFromPlays } from 'match/utils/winningAndLosingPlaysUtil';
import PropTypes from 'prop-types';
import { getArrowType } from 'scout/util/scoutWinningAndLosingPlaysUtil';

import customSitesConfig from '_shared/config/customSitesConfig';
import { Alert, Box, ButtonGroup, Flex } from '_shared/designSystem/components';

import CourtWithArrow from './CourtWithArrow';

export default function ScoutWinningAndLosingPlays(props) {
  const { data, handedness, playType, handleWinningAndLosingPlaysButtonClick } = props;

  const [detailedViewShowing, setDetailedViewShowing] = useState(false);
  const [detailedViewId, setDetailedViewId] = useState(null);

  const searchParams = useSearch();

  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams?.tactic) {
      setDetailedViewShowing(true);
      setDetailedViewId(searchParams?.tactic);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isEmpty(data)) {
    return (
      <Box mt={5} px={{ base: 2, lg: 0 }}>
        <Alert message="No Winning and Losing Plays data to show" status="info" />
      </Box>
    );
  }

  const openDetailedView = (item) => {
    setDetailedViewShowing(true);
    setDetailedViewId(item.id);
    navigate({ search: (old) => ({ ...old, tactic: item.id }) });
  };

  const closeDetailedView = () => {
    setDetailedViewShowing(false);
    setDetailedViewId(null);
    navigate({ search: (old) => ({ ...old, tactic: 'none' }) });
  };

  return (
    <Box>
      <Flex rowGap="16px" flexWrap="wrap" mb={4} pt={1} align="center">
        <FilterButtons
          playType={playType}
          handleWinningAndLosingPlaysButtonClick={handleWinningAndLosingPlaysButtonClick}
        />
      </Flex>
      {detailedViewShowing && !isEmpty(getItemFromPlays(data, detailedViewId)) ? (
        <Box>
          <DetailedView
            data={getItemFromPlays(data, detailedViewId)}
            closeDetailedView={closeDetailedView}
            surface={customSitesConfig.defaultScoutCourt}
            isScout
            handedness={handedness}
          />
        </Box>
      ) : (
        <Flex gap={6} flexWrap="wrap" pb={5}>
          {!isEmpty(data) &&
            data.map((item) => {
              const arrowType = getArrowType(item.id, handedness);
              return (
                <Flex key={item.id} direction="column" gap={4} cursor="pointer" onClick={() => openDetailedView(item)}>
                  <Box w="100%">
                    <CourtTopStats data={item} />
                  </Box>
                  <CourtWithArrow
                    surface={customSitesConfig.defaultScoutCourt}
                    arrowType={arrowType}
                    playType={playType}
                  />
                </Flex>
              );
            })}
        </Flex>
      )}
    </Box>
  );
}

ScoutWinningAndLosingPlays.propTypes = {
  data: PropTypes.array,
  handedness: PropTypes.string,
  playType: PropTypes.string,
  handleWinningAndLosingPlaysButtonClick: PropTypes.func
};

const FilterButtons = ({ playType, handleWinningAndLosingPlaysButtonClick }) => (
  <ButtonGroup
    paramName="playType"
    data={[
      { value: 'winning', label: 'Winning', position: 'left' },
      { value: 'losing', label: 'Losing', position: 'right' }
    ]}
    getButtonDataFunction={getPlayTypesButtons}
    handleButtonClick={handleWinningAndLosingPlaysButtonClick}
    selectedItem={playType}
  />
);

export const getPlayTypesButtons = () => {
  return [
    {
      label: 'Winning',
      value: 'winning',
      position: 'left'
    },
    {
      label: 'Losing',
      value: 'losing',
      position: 'right'
    }
  ];
};

export const forehandBackhandLegend = [
  {
    label: 'Forehand',
    color: 'error.200'
  },
  {
    label: 'Backhand',
    color: 'warning.300'
  },
  {
    label: 'Both',
    color: 'grey.300'
  }
];
