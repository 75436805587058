export const scoutTabTypes = {
  INSIGHTS: 'insights',
  SERVE: 'serve',
  RETURN: 'return',
  GROUNDSTROKES: 'groundstrokes',
  CONTACT_POINTS: 'contact-points',
  WINNING_AND_LOSING_PLAYS: 'winning-and-losing-plays',
  WINNING_AND_LOSING_PLAYS_V2: 'winning-and-losing-plays-v2',
  PATTERNS_OF_PLAY: 'patterns-of-play'
};

export const patternsOfPlayGraphicType = {
  DIRECTION: 'direction',
  IN: 'in',
  QUALITY: 'quality',
  DEPTH: 'depth',
  ACCURACY: 'accuracy'
};

export const patternsOfPlayPattern = {
  CONTROL_THE_MIDDLE: 'control_the_middle',
  FROM_THE_DEUCE_SIDE: 'from_the_deuce_side',
  FROM_THE_ADVANTAGE_SIDE: 'from_the_advantage_side',
  VOLLEYS: 'volleys'
};

export const patternsOfPlayShotTypes = {
  FOREHAND: 'forehand',
  BACKHAND: 'backhand',
  GROUNDSTROKE: 'groundstroke'
};

export const MATCH_SELECTOR_COLUMNS = {
  DATE: 'Date',
  YEAR: 'Year',
  TOURNAMENT_NAME: 'Tournament',
  OPPONENT_NAME: 'Opponent',
  RESULT: 'Result',
  SCORE: 'Score',
  ROUND: 'Round',
  SURFACE: 'Surface',
  OPPONENT_RANK: 'Opponent Rank',
  OPPONENT_HAND: 'Opponent Hand'
};
