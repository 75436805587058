import { useQuery } from 'react-query';
import { tournamentsService } from 'tournaments/services/tournamentsService';

export function useTournamentsQuery() {
  return useQuery(['tournamentsService_tournaments'], () => tournamentsService.getTournaments());
}

export function useTournamentQuery(tournamentId) {
  return useQuery(
    ['tournamentsService_tournament', tournamentId],
    () => tournamentsService.getTournament({ tournamentId }),
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: true
    }
  );
}
