import { useEffect, useState } from 'react';

import { useMatch, useNavigate } from '@tanstack/react-location';
import { useSearch } from '@tanstack/react-location';
import isEmpty from 'lodash/isEmpty';
import DataIncompleteAlert from 'match/_shared/DataCompletenessAlert';
import { DATE_INCOMPLETE_ALERT_TYPES, courtGraphicTypes } from 'match/_shared/matchConstants';
import { Averages, OutShotsSwitch, ServePlusOneSwitch } from 'match/components/_shared/Filtering';
import ServiceBoxLocationGraphicTooltip from 'match/components/_shared/ServiceBoxLocationGraphicToolTip';
import CourtContainer from 'match/components/court-graphics/court/CourtContainer';
import { useCheckHidePageContent } from 'match/hooks/dataQualityHooks';
import {
  useCourtGraphicsAveragesQuery,
  useCourtGraphicsQuery,
  useHeaderQuery,
  usePerformanceForMatch
} from 'match/hooks/matchHooks';
import {
  getAveragesTypeButtons,
  getDeuceAdvantageButtons,
  getForehandBackhandButtons,
  getOrientationButtons,
  getPressureBreakButtons,
  getServeButtons,
  getServeLocationButtons,
  getServeLocationMobileButtons,
  isReturnOrRallyPlacement
} from 'match/utils/courtGraphicsUtil';
import { getPlayersButtons } from 'match/utils/matchUtils';
import { getSetButtonData } from 'match/utils/performanceUtil';
import { useRecoilValue } from 'recoil';
import { parseArrayToNumericValues } from 'scout/util/scoutUtil';

import NoPermissionAlert from '_shared/components/NoPermissionAlert';
import { matchStatus } from '_shared/constants/matchTypes';
import { userPermissionTypes } from '_shared/constants/user';
import { useBreakpointValue } from '_shared/designSystem/components';
import { Alert, Box, ButtonGroup, Flex, SecondaryNavBar, Spinner, Text } from '_shared/designSystem/components';
import { userPermissionsState } from '_shared/globalState/atoms';
import { checkPermission } from '_shared/utils/permissions';
import { convertBooleanToYesNo, convertYesNoToBoolean } from '_shared/utils/stringUtil';

import { getMobileAveragesDisplay } from './court/averages/MobileAverages';
import { CourtGraphicsLegendMobile } from './court/CourtGraphicsLegend';
import { ServePlusOneWithCourt } from './serve-plus-one/ServePlusOne';

export default function CourtGraphics() {
  const {
    params: { matchId }
  } = useMatch();
  const [graphicType, setGraphicType] = useState(courtGraphicTypes.SERVE_DIRECTION);
  const [player, setPlayer] = useState('player1');
  const [set, setSet] = useState('all');
  const [serve, setServe] = useState(1);
  const [deuceAdvantage, setDeuceAdvantage] = useState('all');
  const [pressureBreak, setPressureBreak] = useState('all');
  const [forehandBackhand, setForehandBackhand] = useState('all');
  const [orientation, setOrientation] = useState('horizontal');
  const [averagesType, setAveragesType] = useState('placement');
  const [servePlusOne, setServePlusOne] = useState(false);
  const [showAverages, setShowAverages] = useState(false);
  const [outShots, setOutShots] = useState(false);
  const [previousOutShotsValue, setPreviousOutShotsValue] = useState(false);
  const [serveLocation, setServeLocation] = useState([]);
  const queryParams = {
    graphicType,
    player,
    set,
    serve,
    deuceAdvantage,
    pressureBreak,
    showAverages,
    forehandBackhand,
    servePlusOne,
    outShots,
    serveLocation
  };
  const { data } = useHeaderQuery(matchId);
  const fetchParams = { retry: 0 };
  const courtGraphics = useCourtGraphicsQuery(
    matchId,
    queryParams,
    fetchParams,
    data?.match_status === matchStatus.LIVE
  );
  const courtGraphicsAverages = useCourtGraphicsAveragesQuery(
    matchId,
    queryParams,
    fetchParams,
    data?.match_status === matchStatus.LIVE
  );
  const performance = usePerformanceForMatch(matchId);
  const { permissions } = useRecoilValue(userPermissionsState);

  const getServeLocationButtonDataFunction = useBreakpointValue({
    base: getServeLocationMobileButtons,
    md: getServeLocationButtons
  });
  const showTooltip = useBreakpointValue({ base: true, md: false });

  const search = useSearch();
  const navigate = useNavigate();

  useEffect(() => {
    const setUrlParams = (search) => {
      if (search.graphicType) setGraphicType(search.graphicType);
      if (search.player) setPlayer(search.player);
      if (search.set) setSet(search.set);
      if (search.serve) setServe(search.serve);
      if (search.deuceAdvantage) setDeuceAdvantage(search.deuceAdvantage);
      if (search.pressureBreak) setPressureBreak(search.pressureBreak);
      if (search.forehandBackhand) setForehandBackhand(search.forehandBackhand);
      if (search.orientation) setOrientation(search.orientation);
      if (search.averagesType) handleAveragesTypeSetting(search.graphicType, search.averagesType);
      if (search.servePlusOne !== undefined) setServePlusOne(convertYesNoToBoolean(search.servePlusOne));
      if (search.showAverages !== undefined) setShowAverages(convertYesNoToBoolean(search.showAverages));
      if (search.outShots !== undefined) setOutShots(convertYesNoToBoolean(search.outShots));
      if (search.serveLocation !== undefined) {
        const serveLocationArray = parseArrayToNumericValues(search.serveLocation);
        setServeLocation(serveLocationArray);
      }
    };
    setUrlParams(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const hidePageContent = useCheckHidePageContent(performance?.data?.completeness?.status, permissions);

  const handleButtonClick = (paramName, value) => {
    let newValue;
    switch (paramName) {
      case 'player':
        setPlayer(value);
        navigate({ search: (old) => ({ ...old, player: value }) });
        break;
      case 'set':
        newValue = value === set ? 'all' : value;
        setSet(newValue);
        navigate({ search: (old) => ({ ...old, set: newValue }) });
        break;
      case 'serve':
        if (graphicType === courtGraphicTypes.SERVE_PLUS_ONE) newValue = value === serve ? 'all' : value;
        else newValue = value;
        setServe(newValue);
        navigate({ search: (old) => ({ ...old, serve: newValue }) });
        break;
      case 'servePlusOne':
        setServePlusOne(value);
        navigate({ search: (old) => ({ ...old, servePlusOne: convertBooleanToYesNo(value) }) });
        break;
      case 'deuceAdvantage':
        newValue = value === deuceAdvantage ? '' : value;
        setDeuceAdvantage(newValue);
        navigate({ search: (old) => ({ ...old, deuceAdvantage: newValue }) });
        break;
      case 'pressureBreak':
        newValue = value === pressureBreak ? '' : value;
        setPressureBreak(newValue);
        navigate({ search: (old) => ({ ...old, pressureBreak: newValue }) });
        break;
      case 'forehandBackhand':
        newValue = value === forehandBackhand ? '' : value;
        setForehandBackhand(newValue);
        navigate({ search: (old) => ({ ...old, forehandBackhand: newValue }) });
        break;
      case 'orientation':
        setOrientation(value);
        navigate({ search: (old) => ({ ...old, orientation: value }) });
        break;
      case 'averagesType':
        setAveragesType(value);
        navigate({ search: (old) => ({ ...old, averagesType: value }) });
        break;
      case 'serveLocation':
        if (serveLocation.includes(value)) {
          newValue = serveLocation.filter((item) => item !== value);
        } else {
          newValue = [...serveLocation, value];
        }
        setServeLocation(newValue);
        navigate({ search: (old) => ({ ...old, serveLocation: newValue.join(',') }) });
        break;
      default:
        return null;
    }
  };

  const handleMenuItemClick = (value) => {
    setGraphicType(value);
    handleServePlusOneServe(value);
    handleAveragesTypeSetting(graphicType, value);
    navigate({ search: (old) => ({ ...old, graphicType: value }) });
  };

  const handleServePlusOneServe = (graphicType) => {
    if (graphicType === courtGraphicTypes.SERVE_PLUS_ONE) {
      setServe('all');
      navigate({ search: (old) => ({ ...old, serve: 'all' }) });
    } else {
      setServe(1);
      navigate({ search: (old) => ({ ...old, serve: 1 }) });
    }
  };

  const handleAveragesTypeSetting = (graphicType, value) => {
    const buttons = getAveragesTypeButtons(graphicType);
    if (buttons.find((e) => e.value === value)) setAveragesType(value);
    else setAveragesType('placement');

    // Adjust outShots based on averagesType and previous the state for 'placement'
    if (value === 'placement') {
      setOutShots(previousOutShotsValue); // Restore outShots for 'placement'
    } else {
      setOutShots(false); // Set outShots to false if averagesType is not 'placement'
    }
  };

  const handleAveragesToggle = (value) => {
    setShowAverages(value);
    navigate({ search: (old) => ({ ...old, showAverages: convertBooleanToYesNo(value) }) });
  };

  const handleOutShotsToggle = (value) => {
    setOutShots(value);
    if (averagesType === 'placement') {
      setPreviousOutShotsValue(value); // Update previous state when toggling
    }
    navigate({ search: (old) => ({ ...old, outShots: convertBooleanToYesNo(value) }) });
  };

  if (!checkPermission(userPermissionTypes.COURT_GRAPHICS, permissions)) {
    return <NoPermissionAlert />;
  }

  if (courtGraphics?.error || performance?.error)
    throw new Error(`Unable to retrieve court graphics data for match ${matchId}: ${courtGraphics?.error}`);

  if (courtGraphics?.isLoading || performance?.isLoading) {
    return (
      <Flex pt={150} justify="center">
        <Spinner color="primary.500" />
      </Flex>
    );
  }

  if (hidePageContent) {
    return <DataIncompleteAlert messageType={DATE_INCOMPLETE_ALERT_TYPES.HIDE_DATA} />;
  }

  if (isEmpty(courtGraphics?.data))
    return (
      <Box mt={5}>
        <Alert message="No Court Graphics data to show" status="info" />
      </Box>
    );

  const dataNoAverages = courtGraphics?.data?.averages?.[`${averagesType}`];
  const dataWithAverages = courtGraphicsAverages?.data?.averages?.[`${averagesType}`];

  return (
    <Box px={{ base: 2, lg: 0 }}>
      <Box w="100%" px={{ base: 2, lg: 0 }}>
        <Box mb={5} mt={2}>
          <SecondaryNavBar
            items={getSecondaryNavBarItems()}
            activeItem={graphicType}
            onSelectItem={handleMenuItemClick}
          />
        </Box>
        <Flex gap="16px" flexWrap="wrap" mb={5}>
          <ButtonGroup
            paramName="player"
            data={data}
            getButtonDataFunction={getPlayersButtons}
            handleButtonClick={handleButtonClick}
            selectedItem={player}
          />
          <Flex alignItems="center">
            <Text fontSize="sm" fontWeight="medium" color="grey.700" mr={2}>
              Set
            </Text>
            <ButtonGroup
              paramName="set"
              data={data?.match_score?.set_scores.length}
              getButtonDataFunction={getSetButtonData}
              handleButtonClick={handleButtonClick}
              selectedItem={set}
            />
          </Flex>
          {(graphicType === courtGraphicTypes.RALLY_PLACEMENT ||
            graphicType === courtGraphicTypes.RALLY_CONTACT_POINT) && (
            <ServePlusOneSwitch servePlusOne={servePlusOne} handleButtonClick={handleButtonClick} />
          )}
          {![courtGraphicTypes.RALLY_PLACEMENT, courtGraphicTypes.RALLY_CONTACT_POINT].includes(graphicType) && (
            <ButtonGroup
              paramName="serve"
              getButtonDataFunction={getServeButtons}
              handleButtonClick={handleButtonClick}
              selectedItem={serve}
            />
          )}
          {graphicType === courtGraphicTypes.SERVE_PLUS_ONE && (
            <ButtonGroup
              paramName="deuceAdvantage"
              getButtonDataFunction={getDeuceAdvantageButtons}
              handleButtonClick={handleButtonClick}
              selectedItem={deuceAdvantage}
            />
          )}
          {(graphicType === courtGraphicTypes.SERVE_DIRECTION || graphicType === courtGraphicTypes.SERVE_PLUS_ONE) && (
            <ButtonGroup
              paramName="pressureBreak"
              getButtonDataFunction={getPressureBreakButtons}
              handleButtonClick={handleButtonClick}
              selectedItem={pressureBreak}
            />
          )}
          {(graphicType === courtGraphicTypes.RALLY_PLACEMENT ||
            graphicType === courtGraphicTypes.RALLY_CONTACT_POINT) && (
            <ButtonGroup
              paramName="forehandBackhand"
              getButtonDataFunction={getForehandBackhandButtons}
              handleButtonClick={handleButtonClick}
              selectedItem={forehandBackhand}
            />
          )}
          {isReturnOrRallyPlacement(graphicType) && (
            <ButtonGroup
              paramName="orientation"
              getButtonDataFunction={getOrientationButtons}
              handleButtonClick={handleButtonClick}
              selectedItem={orientation}
            />
          )}
          {graphicType !== courtGraphicTypes.SERVE_PLUS_ONE && (
            <ButtonGroup
              paramName="averagesType"
              getButtonDataFunction={getAveragesTypeButtons}
              data={graphicType}
              handleButtonClick={handleButtonClick}
              selectedItem={averagesType}
            />
          )}
          {graphicType === courtGraphicTypes.RETURN_PLACEMENT && (
            <Flex direction="row" gap={2} alignItems="center" flexWrap="wrap">
              <Text fontSize="sm" fontWeight="medium" color="grey.700">
                Serve Location
              </Text>
              {showTooltip && <ServiceBoxLocationGraphicTooltip surface={data?.surface} />}
              <ButtonGroup
                paramName="serveLocation"
                getButtonDataFunction={getServeLocationButtonDataFunction}
                handleButtonClick={handleButtonClick}
                selectedItemMultiple={serveLocation}
              />
            </Flex>
          )}
          {(graphicType === courtGraphicTypes.SERVE_DIRECTION ||
            graphicType === courtGraphicTypes.RETURN_PLACEMENT ||
            graphicType === courtGraphicTypes.RALLY_PLACEMENT) &&
            averagesType === 'placement' && (
              <Flex maxW={400} align="center">
                <OutShotsSwitch outShots={outShots} setOutShots={handleOutShotsToggle} />
              </Flex>
            )}
          {graphicType !== courtGraphicTypes.SERVE_PLUS_ONE && (
            <Flex maxW={400} align="center">
              <Averages showAverages={showAverages} setShowAverages={handleAveragesToggle} />
            </Flex>
          )}
        </Flex>
        {graphicType === courtGraphicTypes.SERVE_DIRECTION && serve === 1 && outShots === true && (
          <Box my={5} maxW="1400px">
            <Alert
              message="Some extreme landing placements are caused by a serve clipping the net and then landing deep in the court"
              status="info"
            />
          </Box>
        )}
      </Box>
      {graphicType === courtGraphicTypes.SERVE_PLUS_ONE ? (
        <Box minWidth="350px" maxW="1400px">
          <Flex justify="center">
            <Box w="100%">
              <ServePlusOneWithCourt surface={data?.surface} data={courtGraphics?.data} />
            </Box>
          </Flex>
        </Box>
      ) : (
        <Box minWidth="350px" maxW="1400px">
          <CourtContainer
            graphicType={graphicType}
            orientation={orientation}
            shots={courtGraphics?.data?.shots}
            surface={data?.surface}
            dataNoAverages={dataNoAverages}
            dataWithAverages={dataWithAverages}
            showAverages={showAverages}
            averagesLoading={courtGraphicsAverages?.isLoading}
            outShots={outShots}
          />
          <Box
            display={{
              base: 'initial',
              md: 'none'
            }}
          >
            <CourtGraphicsLegendMobile graphicType={graphicType} surface={data?.surface} outShots={outShots} />
            {getMobileAveragesDisplay(
              graphicType,
              orientation,
              dataNoAverages,
              dataWithAverages,
              showAverages,
              courtGraphicsAverages?.isLoading
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

function getSecondaryNavBarItems() {
  return [
    {
      label: 'Serve Direction',
      value: courtGraphicTypes.SERVE_DIRECTION
    },
    {
      label: 'Return Contact Point',
      value: courtGraphicTypes.RETURN_CONTACT_POINT
    },
    {
      label: 'Return Placement',
      value: courtGraphicTypes.RETURN_PLACEMENT
    },
    {
      label: 'Serve +1',
      value: courtGraphicTypes.SERVE_PLUS_ONE
    },
    {
      label: 'Rally Contact Point',
      value: courtGraphicTypes.RALLY_CONTACT_POINT
    },
    {
      label: 'Rally Placement',
      value: courtGraphicTypes.RALLY_PLACEMENT
    }
  ];
}
