import { useRecoilValue } from 'recoil';

import { userPermissionTypes } from '_shared/constants/user';
import { HorizontalNavBar } from '_shared/designSystem/components';
import { userPermissionsState } from '_shared/globalState/atoms';
import useFeature from '_shared/utils/hooks/useFeature';
import { checkPermission } from '_shared/utils/permissions';

export default function NavBar(props) {
  const { permissions } = useRecoilValue(userPermissionsState);
  const courtGraphicsEnabled = useFeature('courtGraphics');
  const winningAndLosingPlaysEnabled = useFeature('winningAndLosingPlays');
  const winningAndLosingPlaysEnabledV2 = useFeature('winningAndLosingPlaysV2');
  const patternsOfPlayEnabled = useFeature('patternsOfPlay');
  const matchCentreVideoTabEnabled = useFeature('matchCentreVideoTab');

  const currentUrl = window.location.href;
  let currentTab = 'performance';
  const navBarElements = [
    'performance',
    'court-graphics',
    'winning-and-losing-plays',
    'winning-and-losing-plays-v2',
    'patterns-of-play',
    'video'
  ];
  navBarElements.forEach((item) => {
    if (currentUrl.includes(item)) {
      currentTab = item;
    }
  });

  let menuItems = [];

  if (checkPermission(userPermissionTypes.PERFORMANCE, permissions)) {
    menuItems.push({
      id: 'performance',
      label: 'Performance'
    });
  }

  if (checkPermission(userPermissionTypes.COURT_GRAPHICS, permissions) && courtGraphicsEnabled) {
    menuItems.push({
      id: 'court-graphics',
      label: 'Court Graphics'
    });
  }

  if (checkPermission(userPermissionTypes.WINNING_AND_LOSING_PLAYS, permissions) && winningAndLosingPlaysEnabled) {
    menuItems.push({
      id: 'winning-and-losing-plays',
      label: 'Winning and Losing Plays'
    });
  }

  if (checkPermission(userPermissionTypes.WINNING_AND_LOSING_PLAYS, permissions) && winningAndLosingPlaysEnabledV2) {
    menuItems.push({
      id: 'winning-and-losing-plays-v2',
      label: 'W&L v2'
    });
  }

  if (checkPermission(userPermissionTypes.PATTERNS_OF_PLAY, permissions) && patternsOfPlayEnabled) {
    menuItems.push({
      id: 'patterns-of-play',
      label: 'Patterns of Play'
    });
  }

  if (checkPermission(userPermissionTypes.VIDEO_ANALYSIS, permissions) && matchCentreVideoTabEnabled) {
    menuItems.push({
      id: 'video',
      label: 'Video Analysis'
    });
  }

  return <HorizontalNavBar items={menuItems} activeItem={currentTab} />;
}
