import { movePlayerAverageToScore } from 'headToHead/util/barsUtil';
import isEmpty from 'lodash/isEmpty';
import StatisticsRow from 'match/components/performance/matchStatistics/StatisticsRow';
import PropTypes from 'prop-types';

import { Box, Flex, Text } from '_shared/designSystem/components';
import { isWimbPortal } from '_shared/utils/environment/currentEnv';

import { VIEW_OPTION_VALUES } from '../constants/headToHeadConstants';

const BarsPanel = ({ dataNoAverages, dataWithAverages, showAverages, view, player1Name, player2Name }) => {
  const is52WeekAverages = view === VIEW_OPTION_VALUES.FIFTY_TWO_WEEK_AVERAGES;

  /**
   * The logic here is that from the API, rather than getting two sets of responses for h2h and 52 week, we
   * just get one, which is the h2h one. Since the 52 week score is the same as the player average, we can just
   * move this over. Performance wise it's fine as the object is so small.
   */

  const h2hWithAverages = dataWithAverages;
  const h2hNoAverages = dataNoAverages;

  const _52withAverages = dataWithAverages;
  const _52noAverages = movePlayerAverageToScore(dataWithAverages);

  const metricsWithAverages = is52WeekAverages ? _52withAverages : h2hWithAverages;
  const metricsNoAverages = is52WeekAverages ? _52noAverages : h2hNoAverages;

  const SectionHeading = ({ heading }) => (
    <Flex mt={2} w="100%" alignItems="center" justify="center">
      <Text fontWeight="extrabold" fontSize={{ base: 'md', sm: 'lg', md: 'lg', xl: 'xl' }}>
        {heading}
      </Text>
    </Flex>
  );

  if (isEmpty(dataWithAverages)) return null;

  return (
    <Flex alignItems="center" flexWrap="wrap" justify="center">
      <Flex
        alignItems="center"
        flexWrap="wrap"
        justify="center"
        gap={8}
        boxShadow={isWimbPortal() ? 'none' : { base: 'none', md: 'md' }}
        py="24px"
        pb="40px"
        w="100%"
        backgroundColor="white"
      >
        <Flex display="column" alignItems="center" justify="center" w="100%">
          <SectionHeading heading={dataWithAverages[0]?.label} />
          <Flex mt={2} w="100%" alignItems="center" justify="space-between" px={10}>
            <Text fontSize={{ base: 'md' }} fontWeight="semibold">
              {player1Name}
            </Text>
            <Text fontSize={{ base: 'md' }} fontWeight="semibold">
              {player2Name}
            </Text>
          </Flex>
          {metricsWithAverages.length > 0 &&
            metricsWithAverages?.map((section, sectionIndex) => (
              <Box key={section.id} mt={9}>
                {sectionIndex > 0 && <SectionHeading heading={section.label} />}
                {section?.metrics?.map((metric, metricIndex) => (
                  <Flex mt={4} w="100%" alignItems="center" justify="center" key={metricIndex}>
                    <StatisticsRow
                      data={metricsNoAverages[sectionIndex]?.metrics[metricIndex]}
                      dataWithAverages={metric}
                      label={metric.label}
                      showAverages={showAverages}
                      averagesLoading={false}
                      overrideWidth="100%"
                      hidePlayerAverage={is52WeekAverages}
                      hasLargerText
                    />
                  </Flex>
                ))}
              </Box>
            ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

BarsPanel.propTypes = {
  dataNoAverages: PropTypes.array,
  dataWithAverages: PropTypes.array,
  showAverages: PropTypes.bool,
  view: PropTypes.string,
  player1Name: PropTypes.string,
  player2Name: PropTypes.string
};

export default BarsPanel;
