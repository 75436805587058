import { useSearch } from '@tanstack/react-location';
import PlaysSummaryV2 from 'match/components/winning-and-losing-plays/PlaysSummaryV2';
import { useWinningAndLosingPlaysSettings } from 'match/hooks/winningAndLosingPlaysHooks';
import { sortPlays } from 'match/utils/winningAndLosingPlaysUtil';
import { useQuery } from 'react-query';
import { scoutService } from 'scout/service/scoutService';

const useWinningAndLosingPlaysQuery = (playerId, queryParams, fetchParams, averages) => {
  const newFetchParams = { ...fetchParams };
  return useQuery(
    ['scoutService_winning-and-losing-plays-v2', playerId, queryParams, averages],
    () =>
      scoutService.getWinningAndLosingPlaysV2({
        playerId,
        playerType: queryParams.playerType,
        scoutFormValues: queryParams,
        averages
      }),
    newFetchParams
  );
};

const ScoutPlaysSummary = ({ playerDetailsData, scoutFetchingValues }) => {
  const playerId = playerDetailsData?.player_id;
  const search = useSearch();
  const initialValues = {
    player: 'player1',
    playType: 'all',
    set: 'all',
    volumeType: search.volumeType || 'volume',
    showAverages: search.showAverages === 'yes',
    playerType: search.playerType || 'player'
  };

  const { state: playerSettings } = useWinningAndLosingPlaysSettings(initialValues);

  const queryParams = {
    playerType: playerSettings.playerType,
    ...scoutFetchingValues
  };
  const fetchParams = { retry: 0 };

  const noAveragesData = useWinningAndLosingPlaysQuery(playerId, queryParams, fetchParams, false);
  const withAveragesData = useWinningAndLosingPlaysQuery(playerId, queryParams, fetchParams, true);

  const sortedPlaysNoAverages = sortPlays(
    noAveragesData?.data || [],
    playerSettings.playType,
    playerSettings.volumeType
  );
  const sortedPlaysWithAverages = sortPlays(
    withAveragesData?.data || [],
    playerSettings.playType,
    playerSettings.volumeType
  );

  return (
    <PlaysSummaryV2
      playDataNoAverages={sortedPlaysNoAverages}
      playDataWithAverages={sortedPlaysWithAverages}
      showAverages={playerSettings.showAverages}
      isLoading={noAveragesData?.isLoading}
      averagesLoading={withAveragesData?.isLoading}
      surface="hard"
      volumeType={playerSettings.volumeType}
      isScout
      playerType={playerSettings.playerType}
      playerData={playerDetailsData}
    />
  );
};

export default ScoutPlaysSummary;
