import { useEffect, useState } from 'react';

import { useNavigate, useSearch } from '@tanstack/react-location';
import isEmpty from 'lodash/isEmpty';
import { useWinningAndLosingPlaysSettings } from 'match/hooks/winningAndLosingPlaysHooks';

import SinglePlayGrid from '_shared/components/SinglePlayGrid';
import { Alert, Box } from '_shared/designSystem/components';
import { removeFieldFromUrl, updateUrl } from '_shared/utils/urlUtil';

import PlaysFilters from './PlaysFilters';
import SinglePlayDetailedView from './SinglePlayDetailedView';

const PlaysSummaryV2 = ({
  playDataNoAverages,
  playDataWithAverages,
  showAverages,
  isLoading,
  averagesLoading,
  surface,
  volumeType,
  isScout,
  playerType,
  playerData
}) => {
  const initialValues = {
    player: 'player1',
    playType: 'all',
    set: 'all',
    volumeType: volumeType,
    showAverages: showAverages,
    playerType: playerType
  };

  const navigate = useNavigate();
  const search = useSearch();

  const [detailedViewShowing, setDetailedViewShowing] = useState(false);
  const [playId, setPlayId] = useState(null);

  useEffect(() => {
    if (search.playId) {
      setPlayId(search.playId);
      setDetailedViewShowing(true);
    }
  }, [search.playId]);

  const {
    state: playerSettings,
    handlers: { handleButtonClick, handleAveragesToggle }
  } = useWinningAndLosingPlaysSettings(initialValues);

  const handleSinglePlayClick = (playId) => {
    setPlayId(playId);
    setDetailedViewShowing(true);
    updateUrl('playId', playId, navigate);
  };

  const handleSinglePlayClose = () => {
    setPlayId(null);
    setDetailedViewShowing(false);
    removeFieldFromUrl('playId', navigate);
  };

  return (
    <Box pb={5} minWidth="350px" maxW="1700px">
      <PlaysFilters
        data={playerData}
        player={playerSettings.player}
        set={playerSettings.set}
        volumeType={playerSettings.volumeType}
        playType={playerSettings.playType}
        showAverages={playerSettings.showAverages}
        handleButtonClick={handleButtonClick}
        handleAveragesToggle={handleAveragesToggle}
        isScout={isScout}
        playerType={playerSettings.playerType}
      />
      {isEmpty(playDataNoAverages) && !isLoading ? (
        <Box mt={5} px={{ base: 2, lg: 0 }}>
          <Alert message="No Winning and Losing Plays data to show" status="info" />
        </Box>
      ) : detailedViewShowing ? (
        <Box>
          <SinglePlayDetailedView
            dataNoAverages={playDataNoAverages.find((item) => item.play_id === playId)}
            dataWithAverages={playDataWithAverages.find((item) => item.play_id === playId)}
            showAverages={showAverages}
            isLoading={isLoading}
            averagesLoading={averagesLoading}
            surface={surface}
            volumeType={volumeType}
            isScout={isScout}
            playerType
            handleClose={handleSinglePlayClose}
            isMatchPage={!isScout}
          />
        </Box>
      ) : (
        <SinglePlayGrid
          playDataNoAverages={playDataNoAverages}
          playDataWithAverages={playDataWithAverages}
          showAverages={showAverages}
          isLoading={isLoading}
          averagesLoading={averagesLoading}
          surface={surface}
          volumeType={volumeType}
          isMatchPage={!isScout}
          playerType
          handleSinglePlayClick={handleSinglePlayClick}
          selectedPlayType={playerSettings.playType}
          playerData={playerData}
        />
      )}
    </Box>
  );
};

export default PlaysSummaryV2;
