import isEmpty from 'lodash/isEmpty';
import Chart from 'react-apexcharts';

import { useBreakpointValue } from '_shared/designSystem/components';
import themeColors from '_shared/designSystem/theme/colors';
import { fontSizes } from '_shared/designSystem/theme/typography';

export default function BarChart(props) {
  const { data, categories, width, colors } = props;

  const yAxisFontSize = useBreakpointValue(
    {
      base: fontSizes.xs,
      md: fontSizes.sm
    },
    {
      fallback: fontSizes.sm
    }
  );

  const fontSmall = {
    fontSize: yAxisFontSize,
    fontWeight: 'normal',
    fontFamily: 'Inter, Arial, sans-serif'
  };

  const chart = {
    type: 'bar',
    stacked: true,
    animations: {
      enabled: true,
      easing: 'easeinout',
      speed: 10,
      animateGradually: {
        enabled: true,
        delay: 150
      },
      dynamicAnimation: {
        enabled: true,
        speed: 450
      }
    },
    toolbar: {
      show: false
    }
  };

  const plotOptions = {
    bar: {
      horizontal: true
    }
  };

  const xaxis = {
    categories,
    labels: {
      show: true,
      style: {
        colors: [themeColors.grey['500']],
        ...fontSmall
      }
    }
  };

  const yaxis = {
    show: true,
    labels: {
      show: true,
      align: 'right',
      style: {
        ...fontSmall
      },
      maxWidth: 240
    }
  };

  const fill = {
    opacity: 1
  };

  const legend = {
    show: false
  };

  const graphColors = isEmpty(colors)
    ? [themeColors.success['300'], themeColors.warning['300'], themeColors.error['300']]
    : colors;

  const dataLabels = {
    enabled: false
  };

  const grid = {
    show: true,
    borderColor: themeColors.grey['200'],
    position: 'back',
    xaxis: {
      lines: {
        show: true
      }
    },
    yaxis: {
      lines: {
        show: false
      }
    }
  };

  const tooltip = {
    enabled: true
  };

  const options = {
    chart,
    plotOptions,
    xaxis,
    yaxis,
    fill,
    legend,
    colors: graphColors,
    dataLabels,
    grid,
    tooltip
  };

  return <Chart options={options} series={data} type="bar" width={width} />;
}
