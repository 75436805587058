import PropTypes from 'prop-types';

const scoreType = PropTypes.shape({
  score: PropTypes.number.isRequired,
  frequency: PropTypes.number,
  score_type: PropTypes.string.isRequired,
  player_average: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  tour_average: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])])
});

const scoreTypeWithAverages = PropTypes.shape({
  score: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  frequency: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  score_type: PropTypes.string.isRequired,
  player_average: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  tour_average: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])])
});

const tacticType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  frequency: PropTypes.number.isRequired,
  score_type: PropTypes.string.isRequired
});

const tacticTypeWithAverages = PropTypes.shape({
  id: PropTypes.string.isRequired,
  score: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  frequency: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  score_type: PropTypes.string.isRequired
});

const outcomeType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  frequency: PropTypes.number.isRequired,
  score_type: PropTypes.string.isRequired,
  player_average: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  tour_average: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])])
});

const outcomeTypeWithAverages = PropTypes.shape({
  id: PropTypes.string.isRequired,
  score: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  frequency: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  score_type: PropTypes.string.isRequired,
  player_average: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  tour_average: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])])
});

const shotType = PropTypes.shape({
  from_x: PropTypes.number.isRequired,
  from_y: PropTypes.number.isRequired,
  to_x: PropTypes.number,
  to_y: PropTypes.number,
  shot_type: PropTypes.string.isRequired,
  volley: PropTypes.number.isRequired
});

export const dataNoAveragesObjectType = PropTypes.shape({
  play_id: PropTypes.string.isRequired,
  total: scoreType.isRequired,
  points_won: PropTypes.shape({
    score: PropTypes.number.isRequired,
    score_type: PropTypes.string.isRequired
  }).isRequired,
  volume: PropTypes.shape({
    neutral: scoreType.isRequired,
    winning: scoreType.isRequired,
    losing: scoreType.isRequired
  }).isRequired,
  effectiveness: scoreType.isRequired,
  tactics: PropTypes.arrayOf(tacticType).isRequired,
  outcomes: PropTypes.shape({
    winning: PropTypes.arrayOf(outcomeType).isRequired,
    losing: PropTypes.arrayOf(outcomeType).isRequired
  }).isRequired,
  shots: PropTypes.arrayOf(shotType).isRequired
});

export const dataWithAveragesObjectType = PropTypes.shape({
  play_id: PropTypes.string.isRequired,
  total: scoreTypeWithAverages.isRequired,
  points_won: PropTypes.shape({
    score: PropTypes.number.isRequired,
    score_type: PropTypes.string.isRequired
  }).isRequired,
  volume: PropTypes.shape({
    neutral: scoreTypeWithAverages.isRequired,
    winning: scoreTypeWithAverages.isRequired,
    losing: scoreTypeWithAverages.isRequired
  }).isRequired,
  effectiveness: scoreTypeWithAverages.isRequired,
  tactics: PropTypes.arrayOf(tacticTypeWithAverages).isRequired,
  outcomes: PropTypes.shape({
    winning: PropTypes.arrayOf(outcomeTypeWithAverages).isRequired,
    losing: PropTypes.arrayOf(outcomeTypeWithAverages).isRequired
  }).isRequired,
  shots: PropTypes.arrayOf(
    PropTypes.shape({
      from_x: PropTypes.number.isRequired,
      from_y: PropTypes.number.isRequired,
      to_x: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      to_y: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      shot_type: PropTypes.string.isRequired,
      volley: PropTypes.number.isRequired
    })
  ).isRequired
});
