import { useNavigate } from '@tanstack/react-location';
import PropTypes from 'prop-types';

import { Box, Text, Flex, NationalityFlag } from '_shared/designSystem/components';
import { isWimbPortal } from '_shared/utils/environment/currentEnv';
import { navigateTo } from '_shared/utils/navigationUtils';

const PlayerDetails = ({ nationality, name, seeding, playerId }) => {
  const navigate = useNavigate();

  return (
    <Flex alignItems="center">
      <Box mr="10px">
        <NationalityFlag nationality={nationality} width={18} />
      </Box>
      <Flex gap={1}>
        <Text
          fontSize={isWimbPortal() ? 'matchCardPlayerNames' : 'sm'}
          color="secondary.800"
          whiteSpace="nowrap"
          cursor="pointer"
          _hover={{ color: isWimbPortal() ? 'primary.500' : 'primary.800' }}
          onClick={(e) => {
            e.stopPropagation();
            navigateTo({
              url: `/scout/${playerId}`,
              navigate
            });
          }}
        >
          {name}
        </Text>
        {seeding && (
          <Text fontSize={isWimbPortal() ? 'matchCardPlayerNames' : 'sm'} color="grey.500">
            ({seeding})
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

PlayerDetails.propTypes = {
  nationality: PropTypes.string,
  name: PropTypes.string.isRequired,
  seeding: PropTypes.string,
  playerId: PropTypes.number
};

export default PlayerDetails;
