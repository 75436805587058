import PropTypes from 'prop-types';

import { Flex, Box, Divider } from '_shared/designSystem/components';
import { isWimbPortal } from '_shared/utils/environment/currentEnv';

import { leftColumnMaxWidth } from '../performanceMargins';

const PanelContainer = ({ children, contentWidth }) => {
  const maxMargin = 90;
  const minMargin = 15;
  const maxWidth =
    contentWidth + maxMargin * 2 < leftColumnMaxWidth ? contentWidth + maxMargin * 2 : leftColumnMaxWidth;
  const minWidth = contentWidth + minMargin * 2;

  return (
    <Box>
      <Flex
        justify="center"
        py="24px"
        pb={{ base: '30px', xl: '40px' }}
        boxShadow={isWimbPortal() ? 'none' : { base: 'none', xl: 'md' }}
        maxW={`${maxWidth}px`}
        minW={`${minWidth}px`}
      >
        {children}
      </Flex>
      <Divider display={{ base: 'flex', lg: 'none' }} />
    </Box>
  );
};

export default PanelContainer;

PanelContainer.propTypes = {
  contentWidth: PropTypes.number
};
