import { useNavigate } from '@tanstack/react-location';

import customSitesConfig from '_shared/config/customSitesConfig';
import { userPermissionTypes } from '_shared/constants/user';
import { Badge, Box, Divider, Flex, Icon, Text, useDisclosure, Center } from '_shared/designSystem/components';
import { formatDate } from '_shared/utils/dateUtil';
import useFeature from '_shared/utils/hooks/useFeature';
import { navigateBack } from '_shared/utils/navigationUtils';
import { navigateTo } from '_shared/utils/navigationUtils';
import { checkPermission } from '_shared/utils/permissions';

import { MatchReportCsvModal } from './MatchReportCsvModal';
import { MatchReportModal } from './MatchReportModal';
import { MatchScore } from './MatchScore';
import { MatchTimeAndDuration } from './MatchTimeAndDuration';
import NavBar from './NavBar';

export default function MatchHeaderMobile({
  heading,
  data,
  matchReportEnabled,
  csvReportEnabled,
  permissions,
  matchId
}) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isOpen: isOpenCsv, onClose: onCloseCsv, onOpen: onOpenCsv } = useDisclosure();

  const navigate = useNavigate();
  const headToHeadEnabled = useFeature('headToHead');

  return (
    <Box py={3}>
      <Flex justify="space-between" mb={5} px={2} gap={2}>
        <Box
          onClick={() => {
            navigateBack({
              navigate
            });
          }}
          cursor="pointer"
        >
          <Icon name="arrowLeft" color="primary.500" height={7} width={7} />
        </Box>
        <Flex direction="column" gap={2} align="center">
          <Flex direction="column" align="center" gap={3}>
            <Text fontSize="md" fontWeight="normal" color="grey.500" textAlign="center">
              {data.competition_name} ({data.round}) {formatDate(data.date)}
            </Text>
            {data?.atp_id && <Badge text={data?.atp_id?.toString()} />}
          </Flex>
          <Flex flexDirection="column" center mt={2} gap={2}>
            <MatchTimeAndDuration data={data} />
            {headToHeadEnabled && checkPermission(userPermissionTypes.HEAD_TO_HEAD, permissions) && (
              <Center
                ml={1}
                overflow="hidden"
                _hover={{
                  opacity: 0.6,
                  cursor: 'pointer'
                }}
                onClick={(e) => {
                  if (!data.player_details.player1.member1.player_id || !data.player_details.player2.member1.player_id)
                    return;
                  navigateTo({
                    url: `/head-to-head?player1=${data.player_details.player1.member1.player_id}&player2=${data.player_details.player2.member1.player_id}`,
                    navigate,
                    storeScroll: false
                  });
                }}
              >
                <Icon name="users" width="22px" height="22px" color="primary.500" />
              </Center>
            )}
            <MatchScore data={data} />
          </Flex>
          <NavBar />
        </Flex>
        <Flex gap={2}>
          {matchReportEnabled &&
            data?.match_report_available &&
            customSitesConfig.showMatchReportIcon &&
            checkPermission(userPermissionTypes.MATCH_REPORT, permissions) && (
              <Box>
                <Box cursor="pointer" onClick={onOpen}>
                  <Icon name="fileText" color="primary.500" height={6} width={6} />
                </Box>
                <MatchReportModal
                  matchId={matchId}
                  heading={heading}
                  onOpen={onOpen}
                  isOpen={isOpen}
                  onClose={onClose}
                />
              </Box>
            )}
          {csvReportEnabled &&
            data?.csv_report_available &&
            checkPermission(userPermissionTypes.CSV_REPORT, permissions) && (
              <Box>
                <Box cursor="pointer" onClick={onOpenCsv}>
                  <Icon name="csvFile" color="primary.500" height={6} width={6} />
                </Box>
                <MatchReportCsvModal matchId={matchId} onOpen={onOpenCsv} isOpen={isOpenCsv} onClose={onCloseCsv} />
              </Box>
            )}
        </Flex>
      </Flex>
      <Divider />
    </Box>
  );
}
