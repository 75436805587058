import { BREAKDOWN_METRICS_TYPES } from 'match/_shared/matchConstants';
import BalanceOfPowerWithAverages from 'match/components/performance/battles/BalanceOfPowerWithAverages';
import { BaselineBattles } from 'match/components/performance/battles/BaselineBattles';
import BreakdownMetrics from 'match/components/performance/breakdownMetrics/BreakdownMetrics';
import CircularMetricsPanel from 'match/components/performance/circularMetrics/CircularMetricsPanel';
import PerformanceGraph from 'match/components/performance/performanceGraph/PerformanceGraph';
import PageHeading from 'matchReport/components/_shared/PageHeading';
import { useMatchReportPerformanceQuery } from 'matchReport/hooks/matchReportHooks';

import { Box, Flex } from '_shared/designSystem/components';

const MatchSummary = ({ headerData, performanceData, matchId }) => {
  const player1Name = headerData?.player_details?.player1?.member1?.last_name;
  const player2Name = headerData?.player_details?.player2?.member1?.last_name;

  const showPerformanceGraph = false;

  const graphData = useMatchReportPerformanceQuery(matchId);
  if (graphData.error) throw new Error('Unable to retrieve graph data');
  if (graphData.isLoading) return null;
  if (graphData.data) {
    return (
      <Box>
        <PageHeading>Match Summary</PageHeading>
        <Flex gap="70px">
          <Box pt={5}>
            <Flex direction="column" gap={20} align="center">
              <Box w="450px">
                <BalanceOfPowerWithAverages
                  data={performanceData?.performance?.battles}
                  showAverages={false}
                  player1Name={player1Name}
                  player2Name={player2Name}
                />
                <Box mt={10}>
                  <BaselineBattles
                    data={performanceData?.performance?.battles}
                    dataWithAverages={performanceData?.dataWithAverages?.performance?.battles}
                    averagesLoading={performanceData?.averagesLoading}
                    showAverages={performanceData?.showAverages}
                    player1Name={player1Name}
                    player2Name={player2Name}
                  />
                </Box>
                <Box mt={10}>
                  <BreakdownMetrics
                    graphicType={BREAKDOWN_METRICS_TYPES.SHOT_VARIATION}
                    dataNoAverages={performanceData?.performance?.breakdown_metrics?.variation}
                    dataWithAverages={performanceData?.performance?.breakdown_metrics?.variation}
                    isMatch={true}
                    players={performanceData?.players}
                    showAverages={false}
                    averagesLoading={false}
                    isReport
                  />
                </Box>
              </Box>
              {showPerformanceGraph && (
                <Box w="600px" border="solid 1px" borderColor="grey.300" p={3}>
                  <PerformanceGraph
                    players={performanceData?.player_details}
                    data={graphData?.data?.performance?.performance_rating_game}
                    showLastEightGames={false}
                    performanceGraphType="performanceTracker"
                    setPerformanceGraphType={null}
                    currentScoreRangeFilter={null}
                  />
                </Box>
              )}
            </Flex>
          </Box>
          <Box>
            <CircularMetricsPanel
              heading="Attack and Defence"
              tooltipText="How often a player has won the point when attacking (Conversion) or defending (Steal)"
              player1Name={player1Name}
              player2Name={player2Name}
              data={performanceData?.performance?.comparison_metrics?.capitalisation}
              type="medium"
              showAverages={false}
              orderOfMetrics={['conversion', 'steal']}
            />
            <Box mt={10}>
              <CircularMetricsPanel
                heading="Shot Quality"
                tooltipText="The quality of the player's four main shots on a 0-10 scale"
                player1Name={player1Name}
                player2Name={player2Name}
                data={performanceData?.performance?.comparison_metrics?.shot_quality}
                type="light"
                showAverages={false}
                orderOfMetrics={['serve', 'return', 'forehand', 'backhand']}
              />
            </Box>
          </Box>
        </Flex>
      </Box>
    );
  }
  return null;
};

export default MatchSummary;
