import { useEffect, useMemo, useRef, useState } from 'react';

import { useMatch, useNavigate } from '@tanstack/react-location';
import isEmpty from 'lodash/isEmpty';
import DataIncompleteAlert from 'match/_shared/DataCompletenessAlert';
import { DATE_INCOMPLETE_ALERT_TYPES } from 'match/_shared/matchConstants';
import {
  fullMatchVideoType,
  statusAlertMap,
  videoPlayerHeightBreakpoints,
  videoPlayerWidthBreakpoints,
  videoPlaylistFields,
  videoStatusType,
  videoTexts
} from 'match/constants/videoConstants';
import { useCheckHidePageContent } from 'match/hooks/dataQualityHooks';
import { useHeaderQuery, usePerformanceForMatch } from 'match/hooks/matchHooks';
import { matchService } from 'match/services/matchService';
import { createPlaylistInputData, removeFormFilter, getUpdatedFilters } from 'match/utils/videoUtil';
import ReactPlayer from 'react-player';
import { useMutation, useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';

import NoPermissionAlert from '_shared/components/NoPermissionAlert';
import { userPermissionTypes } from '_shared/constants/user';
import {
  Alert,
  Box,
  ErrorMessage,
  Flex,
  LoadingSpinner,
  useBreakpointValue,
  Text
} from '_shared/designSystem/components';
import VideoPlayer from '_shared/designSystem/components/videoPlayer/VideoPlayer';
import { userPermissionsState } from '_shared/globalState/atoms';
import { checkPermission } from '_shared/utils/permissions';
import { removeFieldFromUrl, updateUrl, clearUrlParams } from '_shared/utils/urlUtil';

import ApplyButton from './ApplyButton';
import Chip from './chips/Chip';
import ChipsGroup from './chips/ChipsGroup';
import Filters from './filters/Filters';
import MatchVideoButton from './MatchVideoButton';
import PlaylistControlsDesktop from './playlist/PlaylistControlsDesktop';
import PlaylistDesktop from './playlist/PlaylistDesktop';
import PlaylistMobile from './playlist/PlaylistMobile';
import PlaylistSkeleton, { PlaylistSkeletonMobile } from './playlist/PlaylistSkeleton';

export default function MatchVideo() {
  const [formState, setFormState] = useState({});
  const [playlistCurrentIndex, setCurrentIndex] = useState(0);
  const [showingPlaylist, setShowingPlaylist] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [applyButtonEnabled, setApplyButtonEnabled] = useState(false);
  const nextVideoRef = useRef(null);
  const navigate = useNavigate();
  const {
    params: { matchId }
  } = useMatch();
  const { permissions } = useRecoilValue(userPermissionsState);

  const { data: dataMatchInfo, isLoading: isLoadingMatchInfo } = useHeaderQuery(matchId);
  const performanceData = usePerformanceForMatch(matchId);

  const { data: dataCompleteMatch, error: errorCompleteMatch } = useQuery(
    ['matchService_getVideoCompleteMatch', matchId],
    () => matchService.getVideoCompleteMatch({ matchId })
  );

  const {
    data: dataVideoStatus,
    isLoading: isLoadingVideoStatus,
    error: errorVideoStatus
  } = useQuery(['matchService_getVideoStatus', matchId], () => matchService.getVideoStatus({ matchId }));

  const {
    data: dataPlaylist,
    mutate: mutatePlaylist,
    isLoading: isLoadingPlaylist,
    error: errorPlaylist
  } = useMutation((mutationInputObject) =>
    matchService.getVideoPlaylist({ matchId, formValues: mutationInputObject.values })
  );

  const player1Name = dataMatchInfo?.player_details?.player1?.member1?.last_name;
  const player2Name = dataMatchInfo?.player_details?.player2?.member1?.last_name;

  const playlist = useMemo(() => createPlaylistInputData(dataPlaylist, dataMatchInfo), [dataPlaylist, dataMatchInfo]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const filters = {};
    params.forEach((value, key) => {
      if (key === videoPlaylistFields.SETS) {
        filters[key] = value.split(',').map(Number);
      } else {
        filters[key] = value;
      }
    });
    setFormState(filters);
    if (params.toString()) {
      setShowingPlaylist(true);
      mutatePlaylist({ values: filters });
    } else {
      // Default to Tagged Match as no filters are applied
      setShowingPlaylist(true);
      mutatePlaylist({ values: {} });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (!params.toString()) setCurrentVideoUrl(dataCompleteMatch?.full_video_url);
  }, [dataCompleteMatch]);

  useEffect(() => {
    if (showingPlaylist && !isLoadingPlaylist && dataPlaylist && dataPlaylist.length > 0) {
      setCurrentVideoUrl(dataPlaylist[0].url);
      setCurrentIndex(0);
      setIsPlaying(true);
    }
  }, [showingPlaylist, isLoadingPlaylist, dataPlaylist]);

  useEffect(() => {
    if (dataPlaylist && playlistCurrentIndex < dataPlaylist?.length - 1) {
      const nextVideoUrl = dataPlaylist[playlistCurrentIndex + 1].url;
      if (nextVideoRef.current) {
        nextVideoRef.current.src = nextVideoUrl;
      }
    }
  }, [playlistCurrentIndex, dataPlaylist]);

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const isBase = useBreakpointValue({ base: true, sm: false });
  const videoPlayerWidth = useBreakpointValue(videoPlayerWidthBreakpoints);
  const videoPlayerHeight = useBreakpointValue(videoPlayerHeightBreakpoints);

  const hidePageContent = useCheckHidePageContent(performanceData?.data?.completeness?.status, permissions);

  const handleShowFullMatch = (matchType) => {
    const isFullMatch = matchType === fullMatchVideoType.FULL_MATCH;
    const url = isFullMatch ? dataCompleteMatch.full_video_url : null;

    setCurrentVideoUrl(url);
    setIsPlaying(true);
    setFormState({});
    clearUrlParams(navigate);
    setShowingPlaylist(!isFullMatch);

    if (!isFullMatch) {
      mutatePlaylist({ values: {} });
    }
  };

  const handleStartPlaylist = () => {
    mutatePlaylist({ values: formState });
    setShowingPlaylist(true);
    setCurrentVideoUrl(playlist[0].url);
    setCurrentIndex(0);
    setIsPlaying(true);
    setApplyButtonEnabled(false);
  };

  const handleFilterSelect = (filter, value) => {
    const newFilters = getUpdatedFilters(formState, filter, value);

    if (filter === videoPlaylistFields.SETS) {
      if (newFilters.sets.length > 0) {
        updateUrl(videoPlaylistFields.SETS, newFilters.sets.join(','), navigate);
      } else {
        removeFieldFromUrl(videoPlaylistFields.SETS, navigate);
      }
      if (newFilters.sets.length > 1) {
        removeFieldFromUrl(videoPlaylistFields.FROM_GAME, navigate);
        removeFieldFromUrl(videoPlaylistFields.TO_GAME, navigate);
      }
    } else {
      if (value === 'all' || value === 'both' || value === 'Select') {
        removeFieldFromUrl(filter, navigate);
      } else {
        updateUrl(filter, value, navigate);
      }
    }

    setApplyButtonEnabled(true);
    setFormState(newFilters);
  };

  const handleClearFilters = () => {
    setFormState({});
    clearUrlParams(navigate);
    mutatePlaylist({ values: {} });
    setIsPlaying(true);
  };

  const handleChipsReset = () => {
    handleClearFilters();
  };

  const handleRemoveChip = (filter, value) => {
    let newFilters;
    if (filter === videoPlaylistFields.SETS) {
      newFilters = {
        ...formState,
        sets: formState.sets.filter((set) => set !== value)
      };

      if (newFilters.sets.length === 0) {
        delete newFilters.sets;
        delete newFilters[videoPlaylistFields.FROM_GAME];
        delete newFilters[videoPlaylistFields.TO_GAME];
        removeFieldFromUrl(videoPlaylistFields.SETS, navigate);
        removeFieldFromUrl(videoPlaylistFields.FROM_GAME, navigate);
        removeFieldFromUrl(videoPlaylistFields.TO_GAME, navigate);
      } else {
        updateUrl(videoPlaylistFields.SETS, newFilters.sets.join(','), navigate);
      }
    } else {
      newFilters = removeFormFilter(formState, filter);
      removeFieldFromUrl(filter, navigate);
    }

    setFormState(newFilters);
    if (isEmpty(newFilters)) {
      mutatePlaylist({ values: {} });
      setIsPlaying(true);
    } else {
      setApplyButtonEnabled(true);
    }
  };

  useEffect(() => {
    if (!fullMatchVideoType.FULL_MATCH) {
      if (isEmpty(formState)) {
        setShowingPlaylist(true);
      }
    }
  }, [formState]);

  const handlePlayNext = () => {
    if (playlistCurrentIndex < dataPlaylist?.length - 1) {
      setCurrentVideoUrl(dataPlaylist[playlistCurrentIndex + 1].url);
      setCurrentIndex(playlistCurrentIndex + 1);
      setIsPlaying(true);
    }
  };

  const handlePlayPrev = () => {
    if (playlistCurrentIndex > 0) {
      setCurrentVideoUrl(dataPlaylist[playlistCurrentIndex - 1].url);
      setCurrentIndex(playlistCurrentIndex - 1);
      setIsPlaying(true);
    }
  };

  const handleSelectPointInPlaylist = (index) => {
    if (playlistCurrentIndex !== index) {
      setCurrentVideoUrl(dataPlaylist[index].url);
      setCurrentIndex(index);
      setIsPlaying(true);
    }
  };

  const handleVideoEnd = () => {
    playlistCurrentIndex < dataPlaylist?.length - 1 ? handlePlayNext() : setIsPlaying(false);
  };

  const PreloadNextVideo = () => {
    if (!dataPlaylist || playlistCurrentIndex >= dataPlaylist?.length - 1) return null;

    const nextVideoUrl = dataPlaylist[playlistCurrentIndex + 1].url;
    return (
      <div style={{ display: 'none' }}>
        <ReactPlayer
          url={nextVideoUrl}
          width="0"
          height="0"
          volume={0}
          muted
          playing
          playsinline
          config={{
            file: {
              attributes: {
                'webkit-playsinline': true,
                'x5-playsinline': true
              }
            }
          }}
        />
      </div>
    );
  };

  const TaggedMatchControlDisplay = () =>
    isEmpty(formState) &&
    showingPlaylist && (
      <Flex direction="row" justify="space-between">
        <Flex justifyContent="flex-start" alignItems="center" mr={2} gap={2}>
          <Chip label="TAGGED MATCH" />
        </Flex>
        <PlaylistControlsDesktop handlePlayPrev={handlePlayPrev} handlePlayNext={handlePlayNext} />
      </Flex>
    );

  const StatusAlert = ({ dataVideoStatus }) => {
    const alertProps = statusAlertMap[dataVideoStatus];
    if (alertProps) {
      return <Alert status={alertProps.status} message={alertProps.message} />;
    }
    return null;
  };

  if (!checkPermission(userPermissionTypes.VIDEO_ANALYSIS, permissions)) {
    return <NoPermissionAlert />;
  }

  if (hidePageContent) {
    return <DataIncompleteAlert messageType={DATE_INCOMPLETE_ALERT_TYPES.HIDE_DATA} />;
  }

  if (errorVideoStatus || errorCompleteMatch || errorPlaylist) return <ErrorMessage message={videoTexts.API_ERROR} />;

  if (isLoadingMatchInfo || isLoadingVideoStatus) return <LoadingSpinner />;

  if (dataVideoStatus?.status !== videoStatusType.READY) {
    return <StatusAlert dataVideoStatus={dataVideoStatus?.status} />;
  }

  return (
    <Box>
      {dataVideoStatus?.flagged === true && <Alert status="warning" message={videoTexts.FLAGGED} />}
      <Flex py={5} gap="20px" w="100%">
        <Flex direction="column" maxW={videoPlayerWidth} gap={4}>
          {showingPlaylist && isEmpty(playlist) && !isLoadingPlaylist ? (
            <Alert message="No results for those filters" status="info" />
          ) : (
            <Box>
              <VideoPlayer
                width={videoPlayerWidth}
                height={videoPlayerHeight}
                url={currentVideoUrl}
                isPlaying={isPlaying}
                hasPlaylist={true}
                handlePlayPrev={handlePlayPrev}
                handlePlayNext={handlePlayNext}
                handleVideoEnd={handleVideoEnd}
                disableNext={playlistCurrentIndex >= dataPlaylist?.length - 1}
                disablePrev={playlistCurrentIndex <= 0}
                setIsPlaying={setIsPlaying}
              />
              <PreloadNextVideo />
            </Box>
          )}
          {showingPlaylist &&
            isMobile &&
            (isLoadingPlaylist ? (
              <Box w="100%">
                <PlaylistSkeletonMobile />
              </Box>
            ) : (
              <Flex justify="space-between" gap={3}>
                <PlaylistMobile
                  playlist={playlist}
                  onSelectPoint={handleSelectPointInPlaylist}
                  playlistCurrentIndex={playlistCurrentIndex}
                  currentIndex={playlistCurrentIndex}
                />
                {!isBase && (
                  <Flex align="flex-start" pt={{ base: 0, sm: 1, md: 1.5 }}>
                    <PlaylistControlsDesktop handlePlayPrev={handlePlayPrev} handlePlayNext={handlePlayNext} />
                  </Flex>
                )}
              </Flex>
            ))}
          {!isEmpty(formState) && (
            <>
              <Flex justify="space-between">
                <Flex gap={3}>
                  <Box minW="75px">
                    <ApplyButton onClick={handleStartPlaylist} disabled={!applyButtonEnabled} />
                  </Box>
                  <Flex gap={3} align="center">
                    <ChipsGroup
                      player1Name={player1Name}
                      player2Name={player2Name}
                      formState={formState}
                      handleRemoveChip={handleRemoveChip}
                      handleReset={handleChipsReset}
                      setGames={dataMatchInfo?.match_score?.all_scores}
                    />
                  </Flex>
                </Flex>
                {showingPlaylist && (!isMobile || isBase) && (
                  <Flex align="flex-start" pt={{ base: 2, sm: 2 }} ml={2}>
                    <PlaylistControlsDesktop handlePlayPrev={handlePlayPrev} handlePlayNext={handlePlayNext} />
                  </Flex>
                )}
              </Flex>
            </>
          )}
          <TaggedMatchControlDisplay />
          <Filters
            player1Name={player1Name}
            player2Name={player2Name}
            setGames={dataMatchInfo?.match_score?.all_scores}
            formState={formState}
            handleFilterSelect={handleFilterSelect}
          />
          <Flex justify="center">
            <Flex gap={4}>
              <MatchVideoButton
                buttonText={<Text textDecoration={!showingPlaylist ? 'underline' : 'none'}>Full Match</Text>}
                color="secondary.800"
                iconColor="white"
                iconBgColor="primary.500"
                onClick={() => handleShowFullMatch(fullMatchVideoType.FULL_MATCH)}
              />
              <MatchVideoButton
                buttonText={<Text textDecoration={showingPlaylist ? 'underline' : 'none'}>Tagged Match</Text>}
                color="secondary.800"
                iconColor="white"
                iconBgColor="primary.500"
                onClick={() => handleShowFullMatch(fullMatchVideoType.COMPRESSED_MATCH)}
              />
            </Flex>
          </Flex>
        </Flex>
        {showingPlaylist &&
          !isMobile &&
          (isLoadingPlaylist ? (
            <Box h="100%" w="100%" maxW={{ lg: '390px', xl: '420px', '2xl': '460px' }}>
              <PlaylistSkeleton />
            </Box>
          ) : (
            <PlaylistDesktop
              playlist={playlist}
              onSelectPoint={handleSelectPointInPlaylist}
              currentIndex={playlistCurrentIndex}
            />
          ))}
      </Flex>
    </Box>
  );
}
