import { displayScore } from 'match/utils/transformations';

import { Text, Flex } from '_shared/designSystem/components';
import { capitaliseString } from '_shared/utils/stringUtil';

export default function CourtTopStats(props) {
  const { data, hideHeading } = props;
  return (
    <Flex direction="column" gap={1} alignItems="center">
      {!hideHeading && (
        <Text fontSize="medium" fontWeight="semibold" align="center" mb={1} color="secondary.800">
          {capitaliseString(data?.id)}
        </Text>
      )}
      {data?.play_type === 'all' && (
        <Text fontSize="md" fontWeight="semibold" align="center" color="secondary.800">
          {displayScore(data?.total_scores?.total_plays?.score, data?.total_scores?.total_plays?.score_type)}
        </Text>
      )}
      {(data?.play_type === 'winning' || data?.play_type === 'losing') && (
        <Text fontSize="md" fontWeight="semibold" align="center" color="secondary.800">
          {displayScore(data?.total_scores?.net_score?.score, data?.total_scores?.net_score?.score_type)}
        </Text>
      )}
      <Text fontSize="xs" fontWeight="semibold" align="center" color="secondary.800">
        Winning: {displayScore(data?.total_scores?.winning?.score, data?.total_scores?.winning?.score_type)}
      </Text>
      <Text fontSize="xs" fontWeight="semibold" align="center" color="secondary.800">
        Losing: {displayScore(data?.total_scores?.losing?.score, data?.total_scores?.losing?.score_type)}
      </Text>
    </Flex>
  );
}
