import PlayerDetails from 'match/components/header/PlayerDetails';
import MatchStatusText from 'matchList/components/_shared/MatchStatusText';

import { matchStatus } from '_shared/constants/matchTypes';
import { resultName } from '_shared/constants/matchTypes';
import { Box, Center, Flex, Icon, Text } from '_shared/designSystem/components';

export const MatchScore = ({ data }) => {
  const players = ['player1', 'player2'];
  return (
    <Box width="100%">
      <table variant="unstyled" size="sm">
        <tbody>
          {players.map((item) => (
            <tr height="32px" key={item}>
              <td>
                <Box>
                  <PlayerDetails
                    nationality={data?.player_details?.[`${item}`]?.member1?.nationality}
                    name={`${data?.player_details?.[`${item}`]?.member1?.initial}. ${
                      data?.player_details?.[`${item}`]?.member1?.last_name
                    }`}
                    seeding={data?.player_details?.[`${item}`]?.seeding}
                    playerId={data?.player_details?.[`${item}`]?.member1.player_id}
                  />
                </Box>
              </td>
              <td>
                <ServerIcon data={data} player={item} />
              </td>
              <td>
                <Box>
                  <GameScore setScores={data?.match_score?.set_scores} player={item} />
                </Box>
              </td>
              {data?.match_status === matchStatus.LIVE &&
                data?.match_score?.in_progress_game_score?.[`${item}_points`] !== '' && (
                  <td>
                    <PointScore score={data?.match_score?.in_progress_game_score?.[`${item}_points`]} />
                  </td>
                )}
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
};

export const ServerIcon = ({ data, player, showIconAsLive = false }) => {
  if (!data) return null;

  const { match_status, match_score, match_result } = data;

  if (match_status === matchStatus.SUSPENDED) {
    return <MatchStatusText result={matchStatus.SUSPENDED} type="matchScore" />;
  }

  if (match_status === matchStatus.LIVE) {
    const inProgress = match_score?.in_progress_game_score;
    return inProgress?.player_serving === player ? (
      <ServingIcon color={showIconAsLive ? 'secondary.800' : 'primary.500'} />
    ) : null;
  }

  if (match_status === matchStatus.COMPLETE) {
    const hasWon = match_result?.match_winner === player;
    const result_name = match_result?.result_name ?? null;

    switch (result_name) {
      case resultName.WON:
        return hasWon ? <TickIcon /> : null;
      case resultName.WALKOVER:
      case resultName.RETIRED:
      case resultName.DEFAULTED:
        return hasWon ? <TickIcon /> : <MatchStatusText result={result_name} type="matchScore" />;
      case resultName.ABANDONED:
      case resultName.CANCELLED:
        return <MatchStatusText result={result_name} type="matchScore" />;
      default:
        return null;
    }
  }

  return null;
};

export function GameScore(props) {
  const { setScores, player } = props;
  const gapValues = { 1: 5, 2: 4, 3: 3, 4: 2, 5: 2 };
  const gap = gapValues[setScores?.length] || 1;
  return (
    <Flex gap={gap}>
      {setScores?.map((item, index) => {
        return (
          <Center key={index}>
            <Text fontSize="sm" color="secondary.800" fontWeight={item.set_winner === player ? 900 : 0}>
              {item[`${player}_games`]}
            </Text>
          </Center>
        );
      })}
    </Flex>
  );
}

export const PointScore = ({ score, showAsLive }) => {
  return (
    <Center bg={showAsLive ? 'secondary.800' : 'primary.500'} h={6} w={6} borderRadius="sm">
      <Text fontSize="sm" fontWeight="medium" color="white">
        {score}
      </Text>
    </Center>
  );
};

const TickIcon = () => (
  <Center boxSize="32px">
    <Icon name="tick" color="primary.500" height={6} width={6} />
  </Center>
);

const ServingIcon = ({ color = 'primary.500' }) => (
  <Center boxSize="32px">
    <Icon name="tennisBall" color={color} height={4} width={4} />
  </Center>
);

MatchStatusText.defaultProps = {
  type: 'matchList'
};
