import { matchStatus } from '_shared/constants/matchTypes';
import { Flex, Text } from '_shared/designSystem/components';
import { convertMinToHourMin, formatTime } from '_shared/utils/dateUtil';

export const MatchTimeAndDuration = ({ data }) => (
  <Flex
    mb={2}
    justifyContent={{ base: 'center', lg: 'flex-end' }}
    w={{ base: '100%', md: '80px', lg: 'initial' }}
    mt={{ md: 1 }}
  >
    <Text fontSize="sm">{convertMinToHourMin(data?.match_duration)}</Text>
    {data?.match_status === matchStatus.FORTHCOMING && (
      <Flex gap={2}>
        <Text fontSize="sm">{data?.start_time_text}</Text>
        <Text fontSize="sm">{formatTime(data?.start_time)}</Text>
      </Flex>
    )}
  </Flex>
);
