import { dataCompletenessStatus } from '_shared/constants/matchTypes';
import { userPermissionTypes } from '_shared/constants/user';
import { checkPermission } from '_shared/utils/permissions';

export function useCheckHidePageContent(status, permissions) {
  return (
    status === dataCompletenessStatus.INCOMPLETE &&
    !checkPermission(userPermissionTypes.DATA_QUALITY_BANNER_SHOW_DATA, permissions)
  );
}
