import { useEffect, useState } from 'react';

import { useMatch, useNavigate } from '@tanstack/react-location';
import { useSearch } from '@tanstack/react-location';
import { matchHeaderHeight, mobileMatchHeaderHeight } from '_layouts/margins';
import isEmpty from 'lodash/isEmpty';
import DataIncompleteAlert from 'match/_shared/DataCompletenessAlert';
import { DATE_INCOMPLETE_ALERT_TYPES } from 'match/_shared/matchConstants';
import { useCheckHidePageContent } from 'match/hooks/dataQualityHooks';
import { useHeaderQuery, usePerformanceForMatch, useWinningAndLosingPlaysQuery } from 'match/hooks/matchHooks';
import { getPlayersButtons } from 'match/utils/matchUtils';
import { getCourtLegend, getItemFromPlays, getPlayTypesButtons } from 'match/utils/winningAndLosingPlaysUtil';
import { useRecoilValue } from 'recoil';

import NoPermissionAlert from '_shared/components/NoPermissionAlert';
import { userPermissionTypes } from '_shared/constants/user';
import { Alert, Box, ButtonGroup, Flex, Legend, Spinner, useBreakpointValue } from '_shared/designSystem/components';
import { userPermissionsState } from '_shared/globalState/atoms';
import { checkPermission } from '_shared/utils/permissions';

import CourtWithTopStats from './CourtWithTopStats';
import DetailedView from './DetailedView';

export default function PlaysSummary() {
  const {
    params: { matchId }
  } = useMatch();
  const [player, setPlayer] = useState('player1');
  const [playType, setPlayType] = useState('all');
  const [detailedViewShowing, setDetailedViewShowing] = useState(false);
  const [detailedViewId, setDetailedViewId] = useState(null);
  const queryParams = {
    player,
    playType
  };
  const header = useHeaderQuery(matchId);
  const fetchParams = { retry: 0 };
  const winningAndLosingPlays = useWinningAndLosingPlaysQuery(matchId, queryParams, fetchParams);
  const performance = usePerformanceForMatch(matchId);

  const search = useSearch();
  const navigate = useNavigate();

  const { permissions } = useRecoilValue(userPermissionsState);

  useEffect(() => {
    const setUrlParams = (search) => {
      if (search.player) setPlayer(search.player);
      if (search.playType) setPlayType(search.playType);
      if (search.tactic && search.tactic !== 'none') {
        setDetailedViewShowing(true);
        setDetailedViewId(search.tactic);
      }
    };
    setUrlParams(search);
  }, [search]);

  const hidePageContent = useCheckHidePageContent(performance?.data?.completeness?.status, permissions);

  const detailedViewTopScrollDepth = useBreakpointValue(
    {
      base: mobileMatchHeaderHeight,
      md: 0
    },
    {
      fallback: 0
    }
  );

  const handleButtonClick = (paramName, value) => {
    setDetailedViewShowing(false);
    setDetailedViewId(null);
    let newValue;
    switch (paramName) {
      case 'player':
        setPlayer(value);
        navigate({ search: (old) => ({ ...old, player: value }) });
        break;
      case 'playType':
        newValue = value === playType ? 'all' : value;
        setPlayType(newValue);
        setDetailedViewShowing(false);
        setDetailedViewId(null);
        navigate({ search: (old) => ({ ...old, playType: newValue, tactic: 'none' }) });
        window.scrollTo(0, 0);
        break;
      default:
        return null;
    }
  };

  const openDetailedView = (item) => {
    setDetailedViewShowing(true);
    setDetailedViewId(item.id);
    navigate({ search: (old) => ({ ...old, tactic: item.id }) });
    sessionStorage.setItem('winningAndLosingPlaysScrollPosition', window.scrollY);
    scrollToDetailedViewTop();
  };

  const closeDetailedView = () => {
    setDetailedViewShowing(false);
    setDetailedViewId(null);
    navigate({ search: (old) => ({ ...old, tactic: 'none' }) });
    scrollToSummaryLocation();
  };

  const scrollToDetailedViewTop = () => {
    setTimeout(function () {
      window.scrollTo(0, detailedViewTopScrollDepth);
    }, 2);
  };

  const scrollToSummaryLocation = () => {
    const scrollPosition = sessionStorage.getItem('winningAndLosingPlaysScrollPosition');
    if (scrollPosition) {
      setTimeout(function () {
        window.scrollTo(0, scrollPosition);
      }, 2);
      sessionStorage.removeItem('winningAndLosingPlaysScrollPosition');
    }
  };

  if (!checkPermission(userPermissionTypes.WINNING_AND_LOSING_PLAYS, permissions)) {
    return <NoPermissionAlert />;
  }

  if (winningAndLosingPlays?.error || performance?.error)
    throw new Error(
      `Unable to retrieve winning and losing plays for match ${matchId}: ${winningAndLosingPlays?.error}`
    );

  if (winningAndLosingPlays?.isLoading || performance?.isLoading) {
    return (
      <Flex pt={150} justify="center">
        <Spinner color="primary.500" />
      </Flex>
    );
  }

  if (hidePageContent) {
    return <DataIncompleteAlert messageType={DATE_INCOMPLETE_ALERT_TYPES.HIDE_DATA} />;
  }

  const data = winningAndLosingPlays?.data;

  return (
    <Box pb={5} minWidth="350px" maxW="1700px">
      <Box
        w="100%"
        zIndex={2}
        px={{ base: 2, lg: 0 }}
        position={{ base: 'relative', md: 'sticky' }}
        bg="white"
        top={{ base: null, md: 0, lg: `${matchHeaderHeight}px` }}
      >
        <Flex rowGap="16px" flexWrap="wrap" mb={5} align="center" py={2}>
          <ButtonGroup
            paramName="player"
            data={header?.data}
            getButtonDataFunction={getPlayersButtons}
            handleButtonClick={handleButtonClick}
            selectedItem={player}
          />
          <ButtonGroup
            paramName="playType"
            data={header?.data}
            getButtonDataFunction={getPlayTypesButtons}
            handleButtonClick={handleButtonClick}
            selectedItem={playType}
          />
          <Legend direction="horizontal" fields={getCourtLegend(playType)} />
        </Flex>
      </Box>
      {isEmpty(winningAndLosingPlays?.data) && (
        <Box mt={5} px={{ base: 2, lg: 0 }}>
          <Alert message="No Winning and Losing Plays data to show" status="info" />
        </Box>
      )}
      {detailedViewShowing && !isEmpty(getItemFromPlays(data, detailedViewId)) ? (
        <Box>
          <DetailedView
            data={getItemFromPlays(data, detailedViewId)}
            closeDetailedView={closeDetailedView}
            surface={header?.data?.surface}
          />
        </Box>
      ) : (
        <Flex gap={6} flexWrap="wrap" justify="center">
          {!isEmpty(data) &&
            data.map((item, index) => (
              <CourtWithTopStats
                key={index}
                data={item}
                openDetailedView={openDetailedView}
                surface={header?.data?.surface}
              />
            ))}
        </Flex>
      )}
    </Box>
  );
}
