import { useEffect } from 'react';

import { Link, Outlet, useLocation, useMatch, useNavigate } from '@tanstack/react-location';
import { matchHeaderHeight, topMenuHeight, topMenuMobileHeight } from '_layouts/margins';
import isEmpty from 'lodash/isEmpty';
import { MatchScore } from 'match/components/header/MatchScore';
import MatchError from 'match/components/performance/MatchError';
import { useHeaderQuery } from 'match/hooks/matchHooks';
import { useRecoilValue } from 'recoil';
import { useSetRecoilState } from 'recoil';

import customSitesConfig from '_shared/config/customSitesConfig';
import { matchStatus } from '_shared/constants/matchTypes';
import { userPermissionTypes } from '_shared/constants/user';
import {
  Alert,
  Badge,
  Box,
  Button,
  ErrorMessage,
  Flex,
  Icon,
  Text,
  useBreakpointValue,
  useDisclosure,
  Center
} from '_shared/designSystem/components';
import ErrorBoundary from '_shared/errors/ErrorBoundary';
import { logBasicMessage } from '_shared/errors/log';
import { showTopMenuState, userPermissionsState } from '_shared/globalState/atoms';
import { mobileHeaderTextState } from '_shared/globalState/atoms';
import { formatDate } from '_shared/utils/dateUtil';
import { isWimbPortal } from '_shared/utils/environment/currentEnv';
import useFeature from '_shared/utils/hooks/useFeature';
import { navigateBack, navigateTo } from '_shared/utils/navigationUtils';
import { checkPermission } from '_shared/utils/permissions';

import MatchHeaderMobile from './MatchHeaderMobile';
import { MatchReportCsvModal } from './MatchReportCsvModal';
import { MatchReportModal } from './MatchReportModal';
import { MatchTimeAndDuration } from './MatchTimeAndDuration';
import NavBar from './NavBar';

export default function MatchHeader() {
  const {
    params: { matchId }
  } = useMatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isOpen: isOpenCsv, onClose: onCloseCsv, onOpen: onOpenCsv } = useDisclosure();

  const matchReportEnabled = useFeature('matchReport');
  const csvReportEnabled = useFeature('csvReport');
  const headToHeadEnabled = useFeature('headToHead');

  const { permissions, company } = useRecoilValue(userPermissionsState);
  const showTopMenu = useRecoilValue(showTopMenuState);
  const setMobileHeaderText = useSetRecoilState(mobileHeaderTextState);
  const setShowTopMenu = useSetRecoilState(showTopMenuState);
  const windowSizeIsMd = useBreakpointValue({ md: true });

  const { isLoading, error, data } = useHeaderQuery(matchId);

  // log info and warnings inside a useEffect so they only get sent once to the remote logs
  useEffect(() => {
    if (error) {
      logBasicMessage('ERROR', `Error fetching from /header/${matchId}: ${error}`);
    } else if (!isLoading) {
      if (data?.error?.includes('Access denied')) {
        logBasicMessage(
          'WARN',
          `Access denied - user trying to access matchId ${matchId} which they do not have permission for`
        );
      } else if (isEmpty(data?.players?.player1?.full_name)) {
        logBasicMessage('WARN', `Match not found - user trying to access matchId ${matchId}`);
      } else {
        logBasicMessage('INFO', `User loaded matchId ${matchId}`);
      }
    }
  }, [matchId, data?.error, data?.players?.player1?.full_name, error, isLoading]);

  useEffect(() => {
    if (!isEmpty(data?.player_details?.player1?.member1?.last_name)) {
      setMobileHeaderText(
        `${data?.player_details?.player1?.member1?.last_name} vs ${data?.player_details?.player2?.member1?.last_name}`
      );
    }
  }, [
    setMobileHeaderText,
    data?.player_details?.player1?.member1?.last_name,
    data?.player_details?.player2?.member1?.last_name
  ]);

  useEffect(() => {
    const hideTopMenuWhenScrolling = () => {
      if (window.scrollY > 50) setShowTopMenu(false);
      else setShowTopMenu(true);
    };
    window.addEventListener('scroll', hideTopMenuWhenScrolling);
    return () => {
      // we only want to remove the event listener if we are not on the match centre
      if (!window.location.pathname.includes('performance')) {
        window.removeEventListener('scroll', hideTopMenuWhenScrolling);
        setShowTopMenu(true);
      }
    };
    // eslint-disable-next-line
  }, []);

  // normal handling of errors
  if (error) {
    throw new Error(`Unable to retrieve header data for match ${matchId}: ${error}`);
  }

  if (isLoading) return null;

  if (data?.error?.includes('Access denied')) {
    return getErrorHeader('Your account does not have permission to view that match.');
  }

  if (isEmpty(data?.player_details?.player1?.member1?.full_name)) {
    return getErrorHeader('That match could not be found, please check your match ID is correct');
  }

  if (
    customSitesConfig.hideLiveMatchStats &&
    data?.match_status !== matchStatus.COMPLETE &&
    !checkPermission(userPermissionTypes.MATCH_IN_PROGRESS, permissions)
  ) {
    return (
      <Flex direction="column" p={4} gap={4}>
        <Alert status="info" message="Match analytics will be available after the match is completed" />
        <Link to="/tournaments">
          <Button size="sm">Back</Button>
        </Link>
      </Flex>
    );
  }

  const heading = `${data?.player_details?.player1?.member1?.full_name} vs ${data?.player_details?.player2?.member1?.full_name}`;

  const Matchup = ({ data, navigate }) => {
    const PlayerName = ({ playerData, onClick }) => (
      <Text
        lineHeight="28px"
        fontSize="2xl"
        fontWeight="medium"
        color="secondary.800"
        onClick={() => onClick(playerData.member1.player_id)}
        cursor="pointer"
        _hover={{ color: 'primary.800' }}
      >
        {playerData.member1.full_name}
      </Text>
    );
    const openScoutPageForPlayer = (playerId) => {
      navigateTo({
        url: `/scout/${playerId}`,
        navigate
      });
    };

    return (
      <Flex gap={2} alignItems="center">
        <PlayerName playerData={data.player_details.player1} onClick={openScoutPageForPlayer} />
        <Text lineHeight="28px" fontSize="2xl" fontWeight="medium" color="secondary.800">
          vs
        </Text>
        <PlayerName playerData={data.player_details.player2} onClick={openScoutPageForPlayer} />
      </Flex>
    );
  };

  return (
    <Box position={{ md: 'sticky' }}>
      <Box
        position={{ md: 'sticky' }}
        bg="white"
        top={showTopMenu ? topMenuHeight : 0}
        transition="top 0.3s ease-in-out"
        height={{ md: `${topMenuMobileHeight + matchHeaderHeight}px`, lg: `${matchHeaderHeight}px` }}
        zIndex={2}
      >
        <ErrorBoundary renderError={() => <MatchError />}>
          {windowSizeIsMd ? (
            <Flex mb={1} justify="space-between" pt={3} flexWrap="wrap">
              <Flex>
                <Box
                  onClick={() => {
                    navigateBack({
                      navigate
                    });
                  }}
                  cursor="pointer"
                  pt={isWimbPortal() ? 4 : 0}
                >
                  <Icon name="arrowLeft" color="primary.500" height={8} width={8} mr={2} />
                </Box>
                <Flex direction="column" gap="10px">
                  <Box pt={isWimbPortal() ? 5 : 0}>
                    <Matchup data={data} navigate={navigate} location={location} />
                  </Box>
                  {!customSitesConfig.hideMatchDetails && (
                    <Flex gap={4} alignItems="center">
                      <Text fontSize="md" fontWeight="normal" color="grey.500">
                        {data.competition_name} ({data.round}) {formatDate(data.date)}
                      </Text>
                      {data?.atp_id && <Badge text={data?.atp_id?.toString()} />}
                      {matchReportEnabled &&
                        data?.match_report_available &&
                        customSitesConfig.showMatchReportIcon &&
                        checkPermission(userPermissionTypes.MATCH_REPORT, permissions) && (
                          <Box>
                            <Box cursor="pointer" onClick={onOpen}>
                              <Icon name="fileText" color="primary.500" height={5} width={5} />
                            </Box>
                            <MatchReportModal
                              matchId={matchId}
                              heading={heading}
                              onOpen={onOpen}
                              isOpen={isOpen}
                              onClose={onClose}
                              company={company}
                            />
                          </Box>
                        )}
                      {csvReportEnabled &&
                        data?.csv_report_available &&
                        checkPermission(userPermissionTypes.CSV_REPORT, permissions) && (
                          <Box>
                            <Box cursor="pointer" onClick={onOpenCsv}>
                              <Icon name="csvFile" color="primary.500" height={5} width={5} />
                            </Box>
                            <MatchReportCsvModal
                              matchId={matchId}
                              onOpen={onOpenCsv}
                              isOpen={isOpenCsv}
                              onClose={onCloseCsv}
                            />
                          </Box>
                        )}
                      {headToHeadEnabled && checkPermission(userPermissionTypes.HEAD_TO_HEAD, permissions) && (
                        <Center
                          display={{ base: 'none', sm: 'flex', md: 'flex', lg: 'none' }}
                          overflow="hidden"
                          _hover={{
                            opacity: 0.6,
                            cursor: 'pointer'
                          }}
                          onClick={(e) => {
                            if (
                              !data.player_details.player1.member1.player_id ||
                              !data.player_details.player2.member1.player_id
                            )
                              return;
                            navigateTo({
                              url: `/head-to-head?player1=${data.player_details.player1.member1.player_id}&player2=${data.player_details.player2.member1.player_id}`,
                              navigate,
                              storeScroll: false
                            });
                          }}
                        >
                          <Icon name="users" width="22px" height="22px" color="primary.500" />
                        </Center>
                      )}
                    </Flex>
                  )}
                  <Box
                    display={{ base: 'none', sm: 'flex', md: 'flex', lg: 'none' }}
                    justifyContent={{ base: 'none', sm: 'center', md: 'flex-end', lg: 'none' }}
                  >
                    <MatchTimeAndDuration data={data} />
                    <MatchScore data={data} />
                  </Box>
                </Flex>
              </Flex>
              <Box display={{ base: 'none', lg: 'initial' }}>
                <Flex align="flex-end" justify="flex-end" gap={4}>
                  {headToHeadEnabled && checkPermission(userPermissionTypes.HEAD_TO_HEAD, permissions) && (
                    <Center
                      mb={3}
                      overflow="hidden"
                      _hover={{
                        opacity: 0.6,
                        cursor: 'pointer'
                      }}
                      onClick={(e) => {
                        if (
                          !data.player_details.player1.member1.player_id ||
                          !data.player_details.player2.member1.player_id
                        )
                          return;
                        navigateTo({
                          url: `/head-to-head?player1=${data.player_details.player1.member1.player_id}&player2=${data.player_details.player2.member1.player_id}`,
                          navigate,
                          storeScroll: false
                        });
                      }}
                    >
                      <Icon name="users" width="16px" height="16px" color="primary.500" />
                    </Center>
                  )}
                  <MatchTimeAndDuration data={data} />
                </Flex>
                <MatchScore data={data} />
              </Box>
            </Flex>
          ) : (
            <MatchHeaderMobile
              goToTournamentPage={() =>
                navigateBack({
                  navigate
                })
              }
              heading={heading}
              data={data}
              matchReportEnabled={matchReportEnabled}
              csvReportEnabled={csvReportEnabled}
              permissions={permissions}
              matchId={matchId}
            />
          )}
          {windowSizeIsMd && (
            <Box px={{ base: 2, lg: 0 }} position="absolute" top={{ lg: '80px' }}>
              <NavBar />
            </Box>
          )}
        </ErrorBoundary>
      </Box>
      <ErrorBoundary renderError={() => <MatchError />}>
        <Outlet />
      </ErrorBoundary>
    </Box>
  );
}

function getErrorHeader(errorText) {
  return (
    <Box p={5}>
      <ErrorMessage message={errorText} />
      <Link to="/">
        <Button mt={10} size="sm">
          Back to home
        </Button>
      </Link>
    </Box>
  );
}
