import wimbColors from '_customSites/wimb/theme/wimbColors';

export const wimbPieChartColors = [
  wimbColors.illustration.dustyPink,
  wimbColors.illustration.lightBlue,
  wimbColors.illustration.strawberries,
  wimbColors.illustration.apple,
  wimbColors.illustration.yellow
];

export const wimbPieChartLegend = [
  {
    label: 'Serve',
    color: 'illustration.dustyPink'
  },
  {
    label: 'Return',
    color: 'illustration.lightBlue'
  },
  {
    label: 'Forehand',
    color: 'illustration.strawberries'
  },
  {
    label: 'Backhand',
    color: 'illustration.apple'
  },
  {
    label: 'Volley',
    color: 'illustration.yellow'
  }
];

export const wimbPerformanceTrackerColors = {
  player1Line: wimbColors.grey[500],
  player2Line: wimbColors.primary[500],
  player1Fill: wimbColors.grey[500],
  player2Fill: wimbColors.primary[500],
  tourAverageLine: wimbColors.tourAverage
};

export const wimbStackedBarColors = {
  serveEffectiveness: [
    { bg: 'wimbGreen.700', text: 'secondary.800' },
    { bg: 'wimbGreen.500', text: 'secondary.800' },
    { bg: 'wimbGreen.300', text: 'secondary.800' },
    { bg: 'grey.200', text: 'secondary.800' },
    { bg: 'rose.50', text: 'secondary.800' },
    { bg: 'pink.100', text: 'secondary.800' },
    { bg: 'error.200', text: 'secondary.800' }
  ],
  shotVariation: [
    { bg: 'grey.200', text: 'secondary.800' },
    { bg: 'wimbGreen.100', text: 'secondary.800' },
    { bg: 'wimbGreen.200', text: 'secondary.800' },
    { bg: 'wimbGreen.400', text: 'secondary.800' },
    { bg: 'wimbGreen.500', text: 'secondary.800' },
    { bg: 'wimbGreen.700', text: 'secondary.800' }
  ]
};
