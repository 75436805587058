import React from 'react';

import isEmpty from 'lodash/isEmpty';
import { displayScore } from 'match/utils/transformations';
import PropTypes from 'prop-types';

import { Flex, Box, VStack, Text, CourtBadge as Badge } from '_shared/designSystem/components';
import { capitaliseString } from '_shared/utils/stringUtil';

/**
 * SingleStackedBar Component
 *
 * This component renders a stacked bar chart. It accepts data for each section of the chart
 * and provides an option to display averages. Users can customize the colors of the bars
 * using the `customStackColors` prop which accepts an array of colors.
 *
 * NOTE: While the `customStackColors` prop allows customization, it is recommended to keep the
 * number of colors in the stack within a reasonable range, preferably not exceeding 7.
 * Excessive colors may result in color repetition, affecting the visual appeal of the chart.
 *
 * If you would like to append custom colors to the default colors, e.g. you would like to use the default but append one other color,
 * you can do so by importing the `defaultColors` array into your file and appending the new color to it:
 *
 * ```js
 * const userCustomColors = [{ bg: 'customColor', text: 'customColor1'}];
 * const combinedColors = [...defaultColors, ...userCustomColors];
 *
 * <SingleStackedBar customStackColors={combinedColors} {...otherProps} />
 * ```
 *
 * @param {Array} dataNoAverages - Data for sections without averages.
 * @param {Array} dataWithAverages - Data for sections with averages.
 * @param {bool} showAverages - Flag to determine whether to display averages.
 * @param {bool} averagesLoading - Flag indicating whether averages are still loading.
 * @param {string} player - The player for whom the chart is rendered (player1 or player2).
 * @param {Array<{bg: string, text: string}>} customStackColors - Custom colors for the stacked bars.
 *
 *
 */

export const defaultColors = [
  { bg: 'primary.25', text: 'secondary.800' },
  { bg: 'primary.100', text: 'secondary.800' },
  { bg: 'primary.300', text: 'secondary.800' },
  { bg: 'primary.500', text: 'secondary.800' },
  { bg: 'primary.600', text: 'white' },
  { bg: 'primary.700', text: 'white' },
  { bg: 'primary.900', text: 'white' }
];

const SingleStackedBar = ({
  dataNoAverages,
  dataWithAverages,
  player,
  showAverages,
  averagesLoading,
  customStackColors
}) => {
  const barColors = customStackColors || defaultColors;
  const columnHeight = 500;

  /**
   * Bar originally showed averages, then preference from Product was to not show them. This enables us to turn it on or off.
   * If turning on, unskip the test 'renders StackedAverages when showAverages is true'
   */
  const showAveragesInBar = false;

  const StackedAverages = ({ data }) => (
    <Flex direction="column" justifyContent="center" alignItems="center">
      <Text color="black" fontWeight="semibold" fontSize="sm" textAlign="center">
        {capitaliseString(data.id)}
      </Text>
      <Box width="50%" mb={1} mt={1}>
        <Badge text={displayScore(data[player]?.score, data[player]?.score_type)} />
      </Box>
      <Box width="50%">
        {['player_average', 'tour_average'].map((type, index) => (
          <Box key={`${data.id}-${index}`} mb={1} data-testid={`${data.id}-averages`}>
            <Badge text={displayScore(data[player][type], data[player].score_type)} />
          </Box>
        ))}
      </Box>
    </Flex>
  );

  const StackedBarSection = ({ data, index }) => {
    if (isEmpty(data[player])) return null;
    const bgColor = barColors.map((color) => color.bg)[index % barColors.length];
    const textColor = barColors.map((color) => color.text)[index % barColors.length];
    const height = getHeight(dataNoAverages, player, parseFloat(data[player]?.score));

    return (
      <Box key={index} height={height} width="100%" backgroundColor={bgColor}>
        <Flex direction="column" justify="center" alignItems="center" height="100%">
          <Text color={textColor} fontWeight="semibold" fontSize="xs" textAlign="center" lineHeight="16px">
            {capitaliseString(data.id)}
          </Text>
          <Flex gap={1}>
            <Text color={textColor} fontSize="xs">
              {displayScore(data[player]?.score, data[player]?.score_type)}
            </Text>
            <Text color={textColor} fontSize="xs">{`(${data[player]?.frequency})`}</Text>
          </Flex>
        </Flex>
      </Box>
    );
  };

  return (
    <>
      {showAverages && showAveragesInBar ? (
        <Flex direction="column" width="110px" gap={1}>
          {dataWithAverages?.map((data, index) => (
            <StackedAverages key={index} data={data} />
          ))}
        </Flex>
      ) : (
        <VStack height={`${columnHeight}px`} width="110px" spacing={0}>
          {dataNoAverages?.map((data, index) => (
            <StackedBarSection key={index} data={data} index={index} />
          ))}
        </VStack>
      )}
    </>
  );
};

SingleStackedBar.propTypes = {
  dataNoAverages: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataWithAverages: PropTypes.arrayOf(PropTypes.object),
  showAverages: PropTypes.bool,
  averagesLoading: PropTypes.bool,
  player: PropTypes.oneOf(['player1', 'player2']).isRequired,
  customStackColors: PropTypes.arrayOf(PropTypes.shape({ bg: PropTypes.string, text: PropTypes.string }))
};

export default SingleStackedBar;

export function getHeight(data, player, score) {
  if (isEmpty(data)) return 0;
  if (data.every((item) => item[player]['score'] === 0)) return `${100 / data.length}%`;
  else return score < 10 ? '45px' : `${score}%`;
}
