import CourtGraphics from 'match/components/court-graphics/CourtGraphics';
import MatchHeader from 'match/components/header/MatchHeader';
import MatchContainer from 'match/components/MatchContainer';
import MatchPatternsOfPlay from 'match/components/patterns_of_play/MatchPatternsOfPlay';
import Performance from 'match/components/performance/Performance';
import MatchVideo from 'match/components/video/MatchVideo';
import MatchPlaysSummary from 'match/components/winning-and-losing-plays/MatchPlaysSummary';
import PlaysSummary from 'match/components/winning-and-losing-plays/PlaysSummary';

const routes = {
  path: 'match',
  element: <MatchContainer />,
  children: [
    {
      path: ':matchId',
      element: <MatchHeader />,
      children: [
        {
          path: 'performance',
          element: <Performance />
        },
        {
          path: 'court-graphics',
          element: <CourtGraphics />
        },
        {
          path: 'winning-and-losing-plays',
          element: <PlaysSummary />
        },
        {
          path: 'winning-and-losing-plays-v2',
          element: <MatchPlaysSummary />
        },
        {
          path: 'patterns-of-play',
          element: <MatchPatternsOfPlay />
        },
        {
          path: 'video',
          element: <MatchVideo />
        },
        {
          element: <Performance />
        }
      ]
    }
  ]
};

export default routes;
