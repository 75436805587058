import isEmpty from 'lodash/isEmpty';

import { searchByString } from '_shared/utils/stringUtil';

export function getInitialPlayersFormState() {
  return {
    gender: 'female'
  };
}

export function searchPlayers(data, formState, playersListPlayerGender) {
  if (isEmpty(data)) return [];
  let result = data;
  if (playersListPlayerGender && playersListPlayerGender !== 'all') {
    result = data.filter((item) => item?.gender === playersListPlayerGender);
  } else if (!isEmpty(formState.gender)) {
    result = data.filter((item) => item?.gender === formState.gender);
  }
  if (formState.playerName) {
    result = searchByString(result, formState.playerName, 'full_name');
  }
  return result;
}

// this is quite fiddly due to dealing with empty params
export function getNewFormState(formState, search, playersListPlayerGender) {
  if (!formState && !search && !playersListPlayerGender) return null;
  const genderExists = search.gender || search.gender === '';
  if (playersListPlayerGender !== 'all') {
    // we aren't showing gender on the page, so only need to deal with player name, but set the gender to config one
    return search.playerName
      ? { ...formState, gender: playersListPlayerGender, playerName: search.playerName }
      : { ...formState, gender: playersListPlayerGender };
  }
  if (genderExists && search.playerName) {
    // both gender and name set
    return { ...formState, gender: search.gender, playerName: search.playerName };
  } else if (genderExists && !search.playerName) {
    // only gender set
    return { ...formState, gender: search.gender };
  } else if (!genderExists && search.playerName) {
    // only name  set
    return { ...formState, playerName: search.playerName };
  }
  return formState;
}
