import isEmpty from 'lodash/isEmpty';
import {
  formatBarChartData,
  formatPieChartData,
  getAllPlaysOutcomesDataInOrder,
  getOutcomesDataInOrder
} from 'match/utils/winningAndLosingPlaysUtil';
import PropTypes from 'prop-types';

import { Table, Tbody, Text, Tr, Td } from '_shared/designSystem/components';
export default function OutcomesTable(props) {
  const { data, playType } = props;

  let tableData = [];
  let combinedTable = [];

  if (playType === 'all') {
    const barChartData = formatBarChartData(data);
    if (isEmpty(barChartData)) return null;

    combinedTable = getAllPlaysOutcomesDataInOrder(barChartData);
    if (isEmpty(combinedTable)) return null;
  } else {
    const pieChartData = formatPieChartData(data);
    if (isEmpty(pieChartData)) return null;

    tableData = getOutcomesDataInOrder(pieChartData, playType);
    if (isEmpty(tableData)) return null;

    for (let i = 0; i < tableData.categories.length; i++) {
      combinedTable.push({ category: tableData.categories[i], value: tableData.data[i] });
    }
  }

  return (
    <Table size="xs" border={null}>
      <Tbody>
        {combinedTable.map((item, index) => (
          <Tr key={index}>
            <Td>
              <Text fontSize="sm" color="grey.600">
                {item.category}
              </Text>
            </Td>
            <Td key={index}>
              <Text fontSize="sm" color="grey.600">
                {item.value}
              </Text>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

OutcomesTable.propTypes = {
  data: PropTypes.array,
  playType: PropTypes.string.isRequired
};
