import {
  AtpMediaBalanceOfPower,
  AtpMediaBattlesMetricSection,
  AtpMediaMomemtum
} from '_customSites/atpmedia/components/AtpMediaPerformanceMetrics';
import { atpMediaMatchSelectorColumns } from '_customSites/atpmedia/layout/matchSelector';
import {
  atpMediaComponentOrderDesktop,
  atpMediaComponentOrderMobile,
  atpMediaNumberOfMetricsInLeftColumnDesktop
} from '_customSites/atpmedia/layout/performance';
import AtpTourLogo, { AtpTourLoginLogo } from '_customSites/atptour/components/AtpTourLogo';
import {
  AtpTourBalanceOfPower,
  AtpTourBattlesMetricSection,
  AtpTourMomemtum
} from '_customSites/atptour/components/AtpTourPerformanceMetrics';
import { atpTourMatchSelectorColumns } from '_customSites/atptour/layout/matchSelector';
import {
  atpTourComponentOrderDesktop,
  atpTourComponentOrderMobile,
  atpTourNumberOfMetricsInLeftColumnDesktop
} from '_customSites/atptour/layout/performance';
import atptourColors from '_customSites/atptour/theme/atptourColors';
import {
  atptourPerformanceTrackerColors,
  atptourPieChartColors,
  atptourPieChartLegend,
  atptourStackedBarColors
} from '_customSites/atptour/theme/atptourComponentColors';
import WimbLogo, { WimbLoginLogo } from '_customSites/wimb/components/WimbLogo';
import {
  WimbBalanceOfPower,
  WimbBattlesMetricSection,
  WimbMomemtum
} from '_customSites/wimb/components/WimbPerformanceMetrics';
import { wimbMatchSelectorColumns } from '_customSites/wimb/layout/matchSelector';
import {
  wimbComponentOrderDesktop,
  wimbComponentOrderMobile,
  wimbNumberOfMetricsInLeftColumnDesktop
} from '_customSites/wimb/layout/performance';
import wimbColors from '_customSites/wimb/theme/wimbColors';
import {
  wimbPerformanceTrackerColors,
  wimbPieChartColors,
  wimbPieChartLegend,
  wimbStackedBarColors
} from '_customSites/wimb/theme/wimbComponentColors';
import { defaultMatchSelectorColumns } from '_layouts/matchSelector';
import BalanceOfPower from 'match/components/performance/battles/BalanceOfPower';
import BattlesMetricSection from 'match/components/performance/battles/BattlesMetricSection';
import Momentum from 'match/components/performance/battles/Momentum';
import {
  componentOrderDesktop,
  componentOrderMobile,
  numberOfMetricsInLeftColumnDesktop
} from 'match/components/performance/layout';
import ScoutInsights from 'scout/components/insights/ScoutInsights';

import TvLogo, { TvLoginLogo } from '_shared/components/logo/TvLogo';
import {
  tvPerformanceTrackerColors,
  tvPieChartColors,
  tvPieChartLegend,
  tvStackedBarColors
} from '_shared/designSystem/theme/chartColors';
import colors from '_shared/designSystem/theme/colors';
import FullScreenError from '_shared/errors/FullScreenError';
import FullScreenErrorAtpMedia from '_shared/errors/FullScreenErrorAtpMedia';
import FullScreenErrorAtptour from '_shared/errors/FullScreenErrorAtptour';
import FullScreenErrorWimb from '_shared/errors/FullScreenErrorWimb';
import { isAtpMediaPortal, isAtpTourPortal, isTvPortal, isWimbPortal } from '_shared/utils/environment/currentEnv';

export const tvConfig = {
  colors: colors,
  logo: <TvLogo />,
  loginLogoComponent: <TvLoginLogo />,
  showLiveMatchesInMenu: false,
  showTodayAtInMenu: true,
  fullScreenErrorComponent: <FullScreenError />,
  redirectAfterLogin: '/tournaments',
  logoutUrlPath: '/login',
  tournamentsUrlPath: '/tournaments',
  showTournamentsFilters: true,
  showTournamentsExplainerText: false,
  hideLiveMatchStats: false,
  hideOptionalMatchListColumns: false,
  showMatchReportIcon: true,
  balanceOfPowerComponent: <BalanceOfPower />,
  balanceOfPowerLabel: 'In Attack',
  momentumComponent: <Momentum />,
  battlesMetricSection: <BattlesMetricSection />,
  pieChartColors: tvPieChartColors,
  pieChartLegend: tvPieChartLegend,
  performanceTrackerColors: tvPerformanceTrackerColors,
  stackedBarColors: tvStackedBarColors,
  playersListNameOnly: false,
  playersListSurnameFirst: false,
  playersListPlayerGender: 'all',
  showScoutWinLossAll: true,
  scoutInsightsComponent: <ScoutInsights />,
  defaultScoutCourt: 'hard',
  showScoutSurfaceAndLast52WeeksFilter: true,
  showScoutReport: true,
  showScoutPlayerHeadshot: false,
  scoutFiltersOnlySeason: false,
  hideOptionalMatchSelectorColumns: false,
  matchSelectorColumns: defaultMatchSelectorColumns,
  performanceComponentOrderDesktop: componentOrderDesktop,
  performanceComponentOrderMobile: componentOrderMobile,
  numberOfPerformanceMetricsInLeftColumnDesktop: numberOfMetricsInLeftColumnDesktop
};

export const wimbConfig = {
  colors: wimbColors,
  logo: <WimbLogo />,
  loginLogoComponent: <WimbLoginLogo />,
  showLiveMatchesInMenu: false,
  showTodayAtInMenu: false,
  fullScreenErrorComponent: <FullScreenErrorWimb />,
  redirectAfterLogin: `/tournaments`,
  logoutUrlPath: '/login',
  tournamentsUrlPath: '/tournaments',
  showTournamentsFilters: false,
  showTournamentsExplainerText: true,
  hideLiveMatchStats: true,
  hideOptionalMatchListColumns: true,
  showMatchReportIcon: false,
  tournamentsExplainerText:
    "Please select the Gentlemen's or Ladies' draw to see a list of match cards. Please select a match to access performance analytics and video analysis. Or use the Scout Area to access aggregated insights and data on a specific player.",
  hideMatchDetails: true,
  balanceOfPowerComponent: <WimbBalanceOfPower />,
  balanceOfPowerLabel: 'In Attack',
  momentumComponent: <WimbMomemtum />,
  battlesMetricSection: <WimbBattlesMetricSection />,
  pieChartColors: wimbPieChartColors,
  pieChartLegend: wimbPieChartLegend,
  performanceTrackerColors: wimbPerformanceTrackerColors,
  stackedBarColors: wimbStackedBarColors,
  playersListNameOnly: true,
  playersListSurnameFirst: true,
  playersListPlayerGender: 'all',
  showScoutWinLossAll: false,
  scoutInsightsComponent: <ScoutInsights />,
  defaultScoutCourt: 'grass',
  showScoutSurfaceAndLast52WeeksFilter: false,
  showScoutReport: false,
  showScoutPlayerHeadshot: true,
  scoutFiltersOnlySeason: true,
  hideOptionalMatchSelectorColumns: true,
  matchSelectorColumns: wimbMatchSelectorColumns,
  performanceComponentOrderDesktop: wimbComponentOrderDesktop,
  performanceComponentOrderMobile: wimbComponentOrderMobile,
  numberOfPerformanceMetricsInLeftColumnDesktop: wimbNumberOfMetricsInLeftColumnDesktop
};

export const atptourConfig = {
  colors: atptourColors,
  logo: <AtpTourLogo />,
  loginLogoComponent: <AtpTourLoginLogo />,
  showLiveMatchesInMenu: false,
  showTodayAtInMenu: true,
  fullScreenErrorComponent: <FullScreenErrorAtptour />,
  hasTermsAndConditionsAcceptance: true,
  redirectAfterLogin: '/terms_and_conditions',
  logoutUrlPath: '/login',
  tournamentsUrlPath: '/tournaments',
  showTournamentsFilters: true,
  showTournamentsExplainerText: false,
  hideLiveMatchStats: false,
  hideOptionalMatchListColumns: false,
  showMatchReportIcon: false,
  balanceOfPowerComponent: <AtpTourBalanceOfPower />,
  balanceOfPowerLabel: 'In Attack',
  momentumComponent: <AtpTourMomemtum />,
  battlesMetricSection: <AtpTourBattlesMetricSection />,
  pieChartColors: atptourPieChartColors,
  pieChartLegend: atptourPieChartLegend,
  performanceTrackerColors: atptourPerformanceTrackerColors,
  stackedBarColors: atptourStackedBarColors,
  playersListNameOnly: false,
  playersListSurnameFirst: false,
  playersListPlayerGender: 'male',
  showScoutWinLossAll: true,
  scoutInsightsComponent: <ScoutInsights />,
  defaultScoutCourt: 'hard',
  showScoutSurfaceAndLast52WeeksFilter: true,
  showScoutReport: false,
  showScoutPlayerHeadshot: true,
  scoutFiltersOnlySeason: false,
  hideOptionalMatchSelectorColumns: false,
  matchSelectorColumns: atpTourMatchSelectorColumns,
  performanceComponentOrderDesktop: atpTourComponentOrderDesktop,
  performanceComponentOrderMobile: atpTourComponentOrderMobile,
  numberOfPerformanceMetricsInLeftColumnDesktop: atpTourNumberOfMetricsInLeftColumnDesktop
};

export const atpmediaConfig = {
  colors: colors,
  logo: <TvLogo />,
  loginLogoComponent: <TvLoginLogo />,
  showLiveMatchesInMenu: true,
  showTodayAtInMenu: true,
  fullScreenErrorComponent: <FullScreenErrorAtpMedia />,
  hasTermsAndConditionsAcceptance: false,
  redirectAfterLogin: '/tournaments',
  logoutUrlPath: '/login',
  tournamentsUrlPath: '/tournaments',
  showTournamentsFilters: true,
  showTournamentsExplainerText: false,
  hideLiveMatchStats: false,
  hideOptionalMatchListColumns: false,
  showMatchReportIcon: false,
  balanceOfPowerComponent: <AtpMediaBalanceOfPower />,
  balanceOfPowerLabel: 'In Attack',
  momentumComponent: <AtpMediaMomemtum />,
  battlesMetricSection: <AtpMediaBattlesMetricSection />,
  pieChartColors: tvPieChartColors,
  pieChartLegend: tvPieChartLegend,
  performanceTrackerColors: tvPerformanceTrackerColors,
  stackedBarColors: tvStackedBarColors,
  playersListNameOnly: false,
  playersListSurnameFirst: false,
  playersListPlayerGender: 'all',
  showScoutWinLossAll: true,
  scoutInsightsComponent: <ScoutInsights />,
  defaultScoutCourt: 'hard',
  showScoutSurfaceAndLast52WeeksFilter: true,
  showScoutReport: false,
  showScoutPlayerHeadshot: true,
  scoutFiltersOnlySeason: false,
  hideOptionalMatchSelectorColumns: false,
  matchSelectorColumns: atpMediaMatchSelectorColumns,
  performanceComponentOrderDesktop: atpMediaComponentOrderDesktop,
  performanceComponentOrderMobile: atpMediaComponentOrderMobile,
  numberOfPerformanceMetricsInLeftColumnDesktop: atpMediaNumberOfMetricsInLeftColumnDesktop
};

let customSitesConfig = {};

if (process.env.NODE_ENV === 'test') {
  customSitesConfig = tvConfig;
} else if (isTvPortal()) customSitesConfig = tvConfig;
else if (isWimbPortal()) customSitesConfig = wimbConfig;
else if (isAtpTourPortal()) customSitesConfig = atptourConfig;
else if (isAtpMediaPortal()) customSitesConfig = atpmediaConfig;

export default customSitesConfig;
