import { useEffect, useState } from 'react';

import { useLocation, useNavigate, useSearch } from '@tanstack/react-location';
import { useGetPlayers } from 'players/services/playersService';
import { getInitialPlayersFormState, getNewFormState, searchPlayers } from 'players/util/playersUtil';
import { useSetRecoilState } from 'recoil';

import customSitesConfig from '_shared/config/customSitesConfig';
import { Box, Flex, Spinner, Text } from '_shared/designSystem/components';
import { mobileHeaderTextState } from '_shared/globalState/atoms';
import { navigateTo } from '_shared/utils/navigationUtils';

import { HeaderWrapper, SearchWrapper } from './_shared/Headers';
import PlayersForm from './PlayersForm';
import PlayersListTable from './PlayersListTable';

export default function PlayersList() {
  const setMobileHeaderText = useSetRecoilState(mobileHeaderTextState);
  const navigate = useNavigate();
  const location = useLocation();
  const [formState, setFormState] = useState(getInitialPlayersFormState());
  const search = useSearch();

  const { data, isLoading } = useGetPlayers();

  useEffect(() => {
    setMobileHeaderText('Scout Area');
  }, [setMobileHeaderText]);

  useEffect(() => {
    if (
      !(search.gender || search.gender === '') &&
      !search.playerName &&
      customSitesConfig.playersListPlayerGender === 'all'
    ) {
      navigate({ search: (old) => ({ ...old, gender: 'female' }) });
    } else {
      const newFormState = getNewFormState(formState, search, customSitesConfig.playersListPlayerGender);
      setFormState(newFormState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormStateUpdate = (paramName, value) => {
    if (paramName === 'gender') {
      const newValue = value === formState.gender ? '' : value;
      const newFormStateGender = { ...formState };
      setFormState({ ...newFormStateGender, gender: newValue });
      navigate({ search: (old) => ({ ...old, gender: newValue }) });
    } else if (paramName.playerName !== null) {
      const newFormStatePlayerName = { ...formState };
      setFormState({ ...newFormStatePlayerName, playerName: paramName.playerName });
      navigate({ search: (old) => ({ ...old, playerName: paramName.playerName }) });
    }
  };

  const columnHeadings = ['Ranking', 'Name'];

  if (isLoading)
    return (
      <>
        <TitleHeader />
        <Flex pt={100} justify="center">
          <Spinner color="primary.500" />
        </Flex>
      </>
    );

  return (
    <Box>
      <HeaderWrapper>
        <TitleHeader />
      </HeaderWrapper>
      <SearchWrapper>
        <PlayersForm
          formState={formState}
          handleFormStateUpdate={handleFormStateUpdate}
          gender={customSitesConfig.playersListPlayerGender}
        />
      </SearchWrapper>
      <Box overflowX={{ base: 'auto', md: 'initial' }}>
        <PlayersListTable
          data={searchPlayers(data, formState, customSitesConfig.playersListPlayerGender)}
          columnHeadings={columnHeadings}
          navigate={navigate}
          location={location}
          openScoutPage={(playerId) => {
            navigateTo({
              url: `/scout/${playerId}`,
              navigate,
              storeScroll: true
            });
          }}
        />
      </Box>
    </Box>
  );
}

const TitleHeader = () => (
  <Box h="50px" bg="white" maxH={{ base: 0, md: '66px' }} display={{ base: 'none', md: 'initial' }}>
    <Text fontSize="3xl" fontWeight="medium" color="secondary.800">
      Scout Area
    </Text>
  </Box>
);
