import PropTypes from 'prop-types';

import { Box, ButtonGroup, Flex, FormStandard } from '_shared/designSystem/components';
import { formFieldTypes } from '_shared/designSystem/components/form/formTypes';
import { isWimbPortal } from '_shared/utils/environment/currentEnv';

export default function PlayersForm({ formState, handleFormStateUpdate, gender }) {
  let data = [
    { value: 'female', label: 'Womens', position: 'left' },
    { value: 'male', label: 'Mens', position: 'right' }
  ];

  if (isWimbPortal()) {
    data = [
      { value: 'female', label: 'Ladies', position: 'left' },
      { value: 'male', label: 'Gentlemen', position: 'right' }
    ];
  }

  return (
    <Flex gap={3} align="center">
      {gender === 'all' && (
        <Box pt="18px">
          <ButtonGroup
            data={data}
            paramName="gender"
            handleButtonClick={handleFormStateUpdate}
            selectedItem={formState.gender}
          />
        </Box>
      )}
      <FormStandard
        formFieldsData={[
          {
            type: formFieldTypes.TEXT,
            label: 'Search Players',
            fieldId: 'playerName'
          }
        ]}
        formInitialValues={
          formState || {
            playerName: ''
          }
        }
        handleFormUpdated={handleFormStateUpdate}
        enableReinitialize={true}
      />
    </Flex>
  );
}

PlayersForm.propTypes = {
  formState: PropTypes.object,
  handleFormStateUpdate: PropTypes.func,
  gender: PropTypes.oneOf(['all', 'male', 'female'])
};

PlayersForm.defaultProps = {
  gender: 'all'
};
