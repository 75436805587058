import React from 'react';

import { displayScore } from 'match/utils/transformations';
import { getFontWeight } from 'match/utils/winningAndLosingPlaysUtil';
import PropTypes from 'prop-types';

import { Box, VStack, HStack, Text, Flex } from '_shared/designSystem/components';

const barHeightMap = {
  xs: '8px',
  sm: '12px',
  md: '20px',
  lg: '28px',
  xl: '40px',
  '2xl': '50px'
};

const barSpacingMap = {
  xs: 1,
  sm: 1,
  md: 2,
  lg: 2,
  xl: 3,
  '2xl': 4
};

const BarGraphic = ({ data, showLabels, barHeight, opacity, selectedPlayType }) => {
  return (
    <VStack spacing={barSpacingMap[barHeight]} align="start" width="100%">
      {data.map((item, index) => (
        <HStack key={index} width="100%">
          {showLabels && (
            <Text
              fontSize="sm"
              width="25%"
              whiteSpace="normal"
              overflowWrap="break-word"
              wordBreak="keep-all"
              fontWeight={getFontWeight(item.label, null, selectedPlayType)}
            >
              {item.label}
            </Text>
          )}
          <Box flex="3" position="relative">
            <Box
              width={`${item.score ?? 0}%`}
              height={barHeightMap[barHeight]}
              bg={item.color ?? 'grey.500'}
              aria-label="bar-chart-bar"
              opacity={opacity}
            />
          </Box>
          <Box flex="1" position="relative">
            <Flex justifyContent="flex-end" fontWeight={getFontWeight(item.label, null, selectedPlayType)} gap={1}>
              {item.score !== null && <Text fontSize="xs">{displayScore(item.score, item.score_type)}</Text>}
              {item.frequency !== null && (
                <Text fontSize="xs" textColor="grey.500">
                  ({item.frequency})
                </Text>
              )}
            </Flex>
          </Box>
        </HStack>
      ))}
    </VStack>
  );
};

BarGraphic.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      frequency: PropTypes.number,
      score: PropTypes.number,
      score_type: PropTypes.string.isRequired,
      color: PropTypes.string
    })
  ).isRequired,
  showLabels: PropTypes.bool,
  barHeight: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', '2xl']),
  opacity: PropTypes.number
};

BarGraphic.defaultProps = {
  showLabels: true,
  showFrequencies: true,
  barHeight: 'lg',
  opacity: 1
};

export default BarGraphic;
