import colors from '_shared/designSystem/theme/colors';

export const tvPieChartColors = [
  colors.error['300'],
  colors.warning['300'],
  colors.success['300'],
  colors.blue['300'],
  colors.purple['300']
];

export const tvPieChartLegend = [
  {
    label: 'Serve',
    color: 'error.300'
  },
  {
    label: 'Return',
    color: 'warning.300'
  },
  {
    label: 'Forehand',
    color: 'success.300'
  },
  {
    label: 'Backhand',
    color: 'blue.300'
  },
  {
    label: 'Volley',
    color: 'purple.300'
  }
];

export const tvPerformanceTrackerColors = {
  player1Line: colors.primary[700],
  player2Line: colors.grey[800],
  player1Fill: colors.primary[200],
  player2Fill: colors.grey[300],
  tourAverageLine: colors.tourAverage
};

export const tvStackedBarColors = {
  serveEffectiveness: [
    { bg: 'primary.600', text: 'secondary.800' },
    { bg: 'primary.400', text: 'secondary.800' },
    { bg: 'primary.200', text: 'secondary.800' },
    { bg: 'grey.200', text: 'secondary.800' },
    { bg: 'rose.50', text: 'secondary.800' },
    { bg: 'pink.100', text: 'secondary.800' },
    { bg: 'error.200', text: 'secondary.800' }
  ],
  shotVariation: [
    { bg: 'grey.200', text: 'secondary.800' },
    { bg: 'primary.100', text: 'secondary.800' },
    { bg: 'primary.200', text: 'secondary.800' },
    { bg: 'primary.300', text: 'secondary.800' },
    { bg: 'primary.500', text: 'secondary.800' },
    { bg: 'primary.600', text: 'secondary.800' }
  ]
};
