import { useEffect } from 'react';

import { useNavigate } from '@tanstack/react-location';
import { useSearch } from '@tanstack/react-location';
import { matchHeaderHeight, mobileHeaderHeight, topMenuMobileHeight } from '_layouts/margins';
import { Averages } from 'match/components/_shared/Filtering';
import { getSetButtonData } from 'match/utils/performanceUtil';

import {
  Flex,
  Text,
  Box,
  ButtonGroupSingle,
  ButtonWithIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useDisclosure,
  Icon
} from '_shared/designSystem/components';
import useFeature from '_shared/utils/hooks/useFeature';
import { convertBooleanToYesNo, convertYesNoToBoolean } from '_shared/utils/stringUtil';

import ScoreRangeFilter, { getRangeAsText } from './ScoreRangeFilter';

export default function PerformanceHeader(props) {
  const {
    showAverages,
    setShowAverages,
    currentSetFilter,
    setCurrentSetFilter,
    setsPlayedCount,
    currentScoreRangeFilter,
    setScoreRangeFilter,
    matchData
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const scoreRangeFilterEnabled = useFeature('scoreRangeFilter');
  const search = useSearch();
  const navigate = useNavigate();

  useEffect(() => {
    if (search.currentSetFilter) setCurrentSetFilter(search.currentSetFilter);
    if (search.showAverages !== undefined) setShowAverages(convertYesNoToBoolean(search.showAverages));
    if (search.currentScoreRangeFilter) setScoreRangeFilter(search.currentScoreRangeFilter);
  }, [search, setCurrentSetFilter, setShowAverages, setScoreRangeFilter]);

  const handleSetFilterButtonClick = (value) => {
    const newValue = value === currentSetFilter ? 'all' : value;
    setCurrentSetFilter(newValue);
    setScoreRangeFilter(null);
    navigate({ search: (old) => ({ ...old, currentSetFilter: newValue, currentScoreRangeFilter: null }) });
  };

  const submitScoreRange = (formData) => {
    setScoreRangeFilter(formData);
    navigate({ search: (old) => ({ ...old, currentScoreRangeFilter: formData }) });
  };

  const handleAveragesToggle = (value) => {
    setShowAverages(value);
    navigate({ search: (old) => ({ ...old, showAverages: convertBooleanToYesNo(value) }) });
  };

  const handleRemoveScoreRangeFilter = () => {
    setScoreRangeFilter(null);
    navigate({ search: (old) => ({ ...old, currentScoreRangeFilter: null }) });
  };

  return (
    <Box
      position={{ base: 'sticky' }}
      bg="white"
      top={{
        base: `${mobileHeaderHeight}px`,
        md: topMenuMobileHeight + matchHeaderHeight,
        lg: matchHeaderHeight
      }}
      transition="top 0.3s ease-in-out"
      zIndex={2}
      px={{ base: 2, lg: 0 }}
    >
      <Flex
        py={2}
        alignItems="center"
        gap={{ base: 2, md: 4, lg: 10 }}
        borderBottomWidth={{ md: '1px' }}
        borderBottomColor="grey.300"
        flexWrap="wrap"
        rowGap="12px"
        w="100%"
      >
        {setsPlayedCount && (
          <SetButtons
            currentSetFilter={currentSetFilter}
            currentScoreRangeFilter={currentScoreRangeFilter}
            setScoreRangeFilter={setScoreRangeFilter}
            handleSetFilterButtonClick={handleSetFilterButtonClick}
            setsPlayedCount={setsPlayedCount}
          />
        )}
        {scoreRangeFilterEnabled && (
          <ScoreRangeButton
            currentScoreRangeFilter={currentScoreRangeFilter}
            handleRemoveScoreRangeFilter={handleRemoveScoreRangeFilter}
            onOpen={onOpen}
          />
        )}
        <Averages showAverages={showAverages} setShowAverages={handleAveragesToggle} />
        <Modal isOpen={isOpen} size="xl" onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ScoreRangeFilter
              currentScoreRangeFilter={currentScoreRangeFilter}
              onClose={onClose}
              submitScoreRange={submitScoreRange}
              data={matchData}
              currentSetFilter={currentSetFilter}
            />
          </ModalContent>
        </Modal>
      </Flex>
    </Box>
  );
}

const SetButtons = (props) => {
  const { handleSetFilterButtonClick, currentSetFilter, setsPlayedCount, currentScoreRangeFilter } = props;
  const buttonData = getSetButtonData(setsPlayedCount);

  if (!buttonData) return null;

  return (
    <Flex alignItems="center">
      <Text fontSize="sm" fontWeight="medium" color="grey.700" mr={2}>
        Set
      </Text>
      {buttonData.map((item) => (
        <ButtonGroupSingle
          key={item.value}
          onClick={() => handleSetFilterButtonClick(item.value)}
          label={item.label}
          position={item.position}
          isSelected={currentScoreRangeFilter?.chosenType ? false : currentSetFilter === item.value}
        />
      ))}
    </Flex>
  );
};

const ScoreRangeButton = ({ currentScoreRangeFilter, handleRemoveScoreRangeFilter, onOpen }) => {
  if (currentScoreRangeFilter) {
    return (
      <Flex gap={2}>
        <ButtonWithIcon
          isSelected
          iconName="chevronDown"
          onClick={onOpen}
          label={
            currentScoreRangeFilter.chosenType === 'slider'
              ? `Last ${currentScoreRangeFilter.sliderGames} Game${currentScoreRangeFilter.sliderGames > 1 ? 's' : ''}`
              : getRangeAsText(currentScoreRangeFilter)
          }
        />
        <Flex justify="center" alignItems="center">
          <Icon
            onClick={() => {
              handleRemoveScoreRangeFilter();
            }}
            cursor="pointer"
            name="crossCircle"
            color="grey.400"
            height={4}
            width={4}
          />
        </Flex>
      </Flex>
    );
  }
  return <ButtonWithIcon iconName="chevronDown" onClick={onOpen} label="Score Range" />;
};
