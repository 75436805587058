import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';

export function capitaliseString(string) {
  if (!string) return null;
  return startCase(toLower(string));
}

export function capitaliseFirstLetterOfString(string) {
  if (!string) return null;
  return string.charAt(0).toUpperCase() + toLower(startCase(toLower(string)).slice(1));
}

export function capitaliseAndPreserveCasing(string) {
  if (!string) return null;
  return string
    .replace(/_/g, ' ')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function convertBooleanToYesNo(param) {
  if (param === true) return 'yes';
  return 'no';
}

export function convertYesNoToBoolean(param) {
  if (param === 'yes') return true;
  return false;
}

export const searchByString = (data, searchString, searchProperty) => {
  if (isEmpty(data)) return [];
  if (isEmpty(searchString) || searchString.trim() === '') return data;

  const searchWords = searchString
    .trim()
    .toLowerCase()
    .split(' ')
    .map((word) => word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));

  const searchPatterns = searchWords.map((word) => new RegExp(word, 'i'));

  return data.filter((element) => {
    let searchValue;
    if (searchProperty === 'full_name') {
      searchValue = `${element.first_name} ${element.last_name}`.toLowerCase();
    } else {
      searchValue = element[searchProperty].toLowerCase();
    }
    return searchPatterns.every((pattern) => pattern.test(searchValue));
  });
};
