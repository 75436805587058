import { useNavigate } from '@tanstack/react-location';
import { GameScore, PointScore, ServerIcon } from 'match/components/header/MatchScore';
import PlayerDetails from 'match/components/header/PlayerDetails';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { startTimeText } from 'schedule/constants/scheduleConstants';
import { getIsDoubles } from 'schedule/util/tournamentUtil';
import { getPlayerHeadshotImage } from 'scout/util/scoutHeaderUtil';

import PlayerImageHeadshot from '_shared/components/PlayerImageHeadshot';
import { matchStatus } from '_shared/constants/matchTypes';
import { userPermissionTypes } from '_shared/constants/user';
import { Badge, Box, Card, Flex, Text, Icon, Center } from '_shared/designSystem/components';
import { userPermissionsState } from '_shared/globalState/atoms';
import { convertMinToHourMin, formatDate, formatDateNoYear, formatTime } from '_shared/utils/dateUtil';
import useFeature from '_shared/utils/hooks/useFeature';
import { navigateTo } from '_shared/utils/navigationUtils';
import { checkPermission } from '_shared/utils/permissions';
import { capitaliseString } from '_shared/utils/stringUtil';

const MatchCard = ({ data, showTournamentInfo, hideDate, h2hCard = false }) => {
  const { match_id, player_details, match_score, match_status, draw_type, is_tracked } = data;

  const isLive = match_status === matchStatus.LIVE;
  const isFutureMatch = match_status === matchStatus.FORTHCOMING;
  const isUntrackedH2HCard = h2hCard && !is_tracked;
  const isDoubles = getIsDoubles(draw_type);
  const headToHeadEnabled = useFeature('headToHead');
  const navigate = useNavigate();

  const openMatchPageHandler = (id) => {
    const url = !isFutureMatch ? `/match/${id}/performance` : `/match/${id}/performance?showAverages=yes`;

    navigateTo({
      url,
      navigate
    });
  };

  return (
    <Card
      disabled={!match_id || isUntrackedH2HCard}
      onClick={() => (match_id && !isUntrackedH2HCard ? openMatchPageHandler(match_id) : null)}
      status={data?.match_status}
    >
      <Flex minW="320px" direction="column" gap={3} minH="100px">
        <MatchDetails
          data={data}
          showTournamentInfo={showTournamentInfo}
          hideDate={hideDate}
          headToHeadEnabled={headToHeadEnabled}
          h2hCard={h2hCard}
          player1Id={player_details?.player1?.member1?.player_id}
          player2Id={player_details?.player2?.member1?.player_id}
        />
        <Flex justify="space-between" pt={!isDoubles ? 3 : 0}>
          <Flex direction="column" gap={2}>
            <MatchCardPlayerDetails data={data} playerData={player_details?.player1} />
            <MatchCardPlayerDetails data={data} playerData={player_details?.player2} />
          </Flex>
          <Flex align="center">
            <Flex direction="column" gap={3}>
              {[1, 2].map((i) => (
                <Flex key={i} align="center" gap={1} justify="flex-end">
                  <ServerIcon data={data} player={`player${i}`} showIconAsLive />
                  <Box mr={3}>
                    <GameScore setScores={match_score?.set_scores} player={`player${i}`} />
                  </Box>
                  {isLive && match_score?.in_progress_game_score?.[`player${i}_points`] !== '' && (
                    <PointScore score={match_score?.in_progress_game_score?.[`player${i}_points`]} showAsLive />
                  )}
                </Flex>
              ))}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

MatchCard.propTypes = {
  data: PropTypes.object,
  openMatchPage: PropTypes.func,
  showTournamentInfo: PropTypes.bool,
  showDate: PropTypes.bool
};

export function createPlayerName(initial, lastName) {
  if (!initial || !lastName) return '';
  return `${initial}. ${lastName}`;
}

export function getDisplayDate(status, date, year) {
  if (status === matchStatus.FORTHCOMING || status === matchStatus.LIVE) return null;
  return year ? formatDate(date) : formatDateNoYear(date);
}

export const MatchDetails = ({
  data,
  showTournamentInfo,
  hideDate,
  headToHeadEnabled,
  h2hCard = false,
  player1Id,
  player2Id
}) => {
  const navigate = useNavigate();
  const { permissions } = useRecoilValue(userPermissionsState);
  const showH2HIcon = headToHeadEnabled && !h2hCard && checkPermission(userPermissionTypes.HEAD_TO_HEAD, permissions);
  return (
    <Flex justify="space-between">
      <Flex gap={showH2HIcon ? 3 : 2} direction={showTournamentInfo ? 'column' : 'row'} align="center">
        <Text fontSize="sm">{showH2HIcon ? data?.round : data?.round_display}</Text>
        <Text fontSize="sm">{!hideDate && getDisplayDate(data?.match_status, data?.date, showTournamentInfo)}</Text>
        {showH2HIcon && (
          <Flex justify="space-between" align="center" gap={2}>
            <Center
              overflow="hidden"
              _hover={{
                opacity: 0.6,
                cursor: 'pointer'
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (!player1Id || !player2Id) return;
                navigateTo({
                  url: `/head-to-head?player1=${player1Id}&player2=${player2Id}`,
                  navigate,
                  storeScroll: false
                });
              }}
            >
              <Icon
                name="users"
                width="18px"
                height="18px"
                mt="2px"
                color={data?.match_status === matchStatus.LIVE ? 'secondary.800' : 'primary.500'}
              />
            </Center>
          </Flex>
        )}
      </Flex>
      <Flex gap={2} flexWrap="wrap" maxW="150px">
        {showTournamentInfo ? (
          <Text
            _hover={{
              cursor: 'pointer',
              color: 'primary.500'
            }}
            onClick={(e) => {
              if (!data?.comp_id) return;
              e.stopPropagation();
              navigateTo({
                url: `/schedule/${data?.comp_id}`,
                navigate
              });
            }}
          >
            {data?.competition_name}
          </Text>
        ) : (
          <>
            <Text fontSize="sm">{convertMinToHourMin(data?.match_duration)}</Text>
            {getDisplayMatchStatus(data?.match_status, data?.start_time_text, data?.start_time)}
          </>
        )}
      </Flex>
    </Flex>
  );
};

export function getDisplayMatchStatus(status, start_time_text, start_time) {
  if (status === matchStatus.COMPLETE) return <Text fontSize="sm">Finished</Text>;
  if (status === matchStatus.LIVE)
    return (
      <Badge text="Live" bgColour="secondary.50" iconColour="secondary.500" textColour="secondary.700" withLeftCircle />
    );
  if (status === matchStatus.FORTHCOMING) {
    if (start_time_text === startTimeText.FOLLOWED_BY) return <Text fontSize="sm">Followed By</Text>;
    return <Text fontSize="sm">{`${start_time_text} ${formatTime(start_time)}`}</Text>;
  }
  return <Text fontSize="sm">{capitaliseString(status)}</Text>;
}

const MatchCardPlayerDetails = ({ data, playerData }) => (
  <Flex gap={2}>
    <Flex align="center">
      <PlayerImageHeadshot
        imageUrl={getPlayerHeadshotImage(playerData?.member1)}
        playerName={playerData?.member1?.last_name}
        w="30px"
        h="30px"
        playerId={playerData?.member1?.player_id}
      />
      {getIsDoubles(data?.draw_type) && (
        <Box ml="-10px">
          <PlayerImageHeadshot
            imageUrl={getPlayerHeadshotImage(playerData?.member2)}
            playerName={playerData?.member2?.last_name}
            w="30px"
            h="30px"
            playerId={playerData?.member2?.player_id}
          />
        </Box>
      )}
    </Flex>
    <Flex align="center">
      <Flex direction="column">
        <PlayerDetails
          nationality={playerData?.member1?.nationality}
          name={createPlayerName(playerData?.member1?.initial, playerData?.member1?.last_name)}
          seeding={playerData?.seeding}
          playerId={playerData?.member1?.player_id}
        />
        {getIsDoubles(data?.draw_type) && (
          <PlayerDetails
            nationality={playerData?.member2?.nationality}
            name={createPlayerName(playerData?.member2?.initial, playerData?.member2?.last_name)}
            seeding={playerData?.seeding}
            playerId={playerData?.member2?.player_id}
          />
        )}
      </Flex>
    </Flex>
  </Flex>
);

export default MatchCard;
