import PropTypes from 'prop-types';

import { Box } from '_shared/designSystem/components';
import colors from '_shared/designSystem/theme/colors';

export default function ShotLine(props) {
  const { shotType, surface, fromX, fromY, toX, toY, canvasWidth, canvasHeight, volley } = props;

  let lineColour, circleBorderColour;

  let ballDiameter = Math.round(0.005 * canvasWidth);
  if (ballDiameter < 2) ballDiameter = 2;

  switch (shotType) {
    case 'winning':
      switch (surface) {
        case 'hard':
          lineColour = colors.success[500];
          circleBorderColour = colors.success[500];
          break;
        case 'clay':
          lineColour = colors.success[500];
          circleBorderColour = colors.success[500];
          break;
        case 'grass':
          lineColour = colors.success[600];
          circleBorderColour = colors.success[600];
          break;
        default:
      }
      break;
    case 'losing':
      switch (surface) {
        case 'hard':
          lineColour = colors.rose[600];
          circleBorderColour = colors.rose[600];
          break;
        case 'clay':
          lineColour = colors.rose[400];
          circleBorderColour = colors.rose[400];
          break;
        case 'grass':
          lineColour = colors.rose[600];
          circleBorderColour = colors.rose[500];
          break;
        default:
      }
      break;
    case 'neutral':
      switch (surface) {
        case 'hard':
          lineColour = colors.grey[600];
          circleBorderColour = colors.grey[600];
          break;
        case 'clay':
          lineColour = colors.grey[600];
          circleBorderColour = colors.grey[600];
          break;
        case 'grass':
          lineColour = colors.grey[600];
          circleBorderColour = colors.grey[600];
          break;
        default:
      }
      break;
    default:
  }

  return (
    <Box position="absolute" top="0" left="0">
      <svg width={canvasWidth} height={canvasHeight} data-testid="shotLine">
        <line x1={fromX} y1={fromY} x2={toX} y2={toY} stroke={lineColour} strokeWidth="1" />
        <circle cx={toX} cy={toY} r={ballDiameter} stroke={circleBorderColour} strokeWidth="2" fill={lineColour} />
        {volley && <circle cx={toX} cy={toY} r={ballDiameter} fill={colors.white} />}
      </svg>
    </Box>
  );
}

ShotLine.propTypes = {
  shotType: PropTypes.oneOf(['winning', 'losing', 'neutral']),
  surface: PropTypes.oneOf(['hard', 'grass', 'clay']),
  fromX: PropTypes.number,
  fromY: PropTypes.number,
  toX: PropTypes.number,
  toY: PropTypes.number,
  canvasWidth: PropTypes.number,
  canvasHeight: PropTypes.number,
  volley: PropTypes.number
};
