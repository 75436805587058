import { useEffect, useState } from 'react';

import { useMatch } from '@tanstack/react-location';
import isEmpty from 'lodash/isEmpty';
import DataIncompleteAlert from 'match/_shared/DataCompletenessAlert';
import { DATE_INCOMPLETE_ALERT_TYPES } from 'match/_shared/matchConstants';
import { useCheckHidePageContent } from 'match/hooks/dataQualityHooks';
import {
  useHeaderQuery,
  usePerformanceNoAveragesQuery,
  usePerformanceRatingGame,
  usePerformanceWithAveragesQuery
} from 'match/hooks/matchHooks';
import { filterBySet } from 'match/utils/performanceUtil';
import { useRecoilValue } from 'recoil';

import { matchStatus } from '_shared/constants/matchTypes';
import { userPermissionTypes } from '_shared/constants/user';
import { Alert, Box, ErrorMessage, Flex, Spinner } from '_shared/designSystem/components';
import { userPermissionsState } from '_shared/globalState/atoms';
import { checkPermission } from '_shared/utils/permissions';

import PerformanceHeader from './PerformanceHeader';
import PerformanceMetrics from './PerformanceMetrics';

const Performance = () => {
  const [showAverages, setShowAverages] = useState(false);
  const [currentSetFilter, setCurrentSetFilter] = useState('all');
  const [currentScoreRangeFilter, setScoreRangeFilter] = useState(null);
  const [setsPlayedCount, setSetsPlayedCount] = useState(null);
  const [matchStatsCategory, setMatchStatsCategory] = useState('serve');
  const [performanceRatingGameBySet, setPerformanceRatingGameBySet] = useState(null);

  const {
    params: { matchId }
  } = useMatch();

  const { data } = useHeaderQuery(matchId);
  const {
    data: performanceData,
    isLoading: performanceIsLoading,
    error: performanceError
  } = usePerformanceNoAveragesQuery(
    matchId,
    currentSetFilter,
    currentScoreRangeFilter,
    data?.match_status === matchStatus.LIVE
  );
  const { data: performanceWithAveragesData, isLoading: performanceWithAveragesIsLoading } =
    usePerformanceWithAveragesQuery(
      matchId,
      currentSetFilter,
      currentScoreRangeFilter,
      data?.match_status === matchStatus.LIVE
    );
  const { data: performanceRatingGameData } = usePerformanceRatingGame(
    matchId,
    null,
    data?.match_status === matchStatus.LIVE
  );

  useEffect(() => {
    if (performanceData?.sets_played_count) setSetsPlayedCount(performanceData?.sets_played_count);
  }, [performanceData]);

  useEffect(() => {
    setPerformanceRatingGameBySet(filterBySet(performanceRatingGameData, currentSetFilter));
  }, [performanceRatingGameData, currentSetFilter]);

  const { permissions } = useRecoilValue(userPermissionsState);

  const hidePageContent = useCheckHidePageContent(performanceData?.completeness?.status, permissions);

  if (!checkPermission(userPermissionTypes.PERFORMANCE, permissions))
    return (
      <Box mt={5}>
        <Alert
          message="You do not have permission to view the performance metrics. If you believe this is an error please contact the site admin"
          status="warning"
        />
      </Box>
    );

  if (performanceError)
    throw new Error(`Unable to retrieve performance data for match ${matchId}: ${performanceError}`);

  if (!performanceIsLoading && isEmpty(performanceData))
    return (
      <Box mt={5}>
        <ErrorMessage message="No performance data for that match is available" />
      </Box>
    );

  if (hidePageContent) {
    return <DataIncompleteAlert messageType={DATE_INCOMPLETE_ALERT_TYPES.HIDE_DATA} />;
  }

  return (
    <Box minH="100vh">
      <PerformanceHeader
        setsPlayedCount={setsPlayedCount}
        setShowAverages={setShowAverages}
        showAverages={showAverages}
        currentSetFilter={currentSetFilter}
        setCurrentSetFilter={setCurrentSetFilter}
        currentScoreRangeFilter={currentScoreRangeFilter}
        setScoreRangeFilter={setScoreRangeFilter}
        matchData={data}
      />
      {performanceIsLoading && (
        <Flex pt={150} justify="center">
          <Spinner color="primary.500" />
        </Flex>
      )}
      {!performanceIsLoading && performanceData && (
        <PerformanceMetrics
          data={performanceData}
          dataWithAverages={performanceWithAveragesData}
          averagesLoading={performanceWithAveragesIsLoading}
          showAverages={showAverages}
          matchStatsCategory={matchStatsCategory}
          setMatchStatsCategory={setMatchStatsCategory}
          currentScoreRangeFilter={currentScoreRangeFilter}
          performanceRatingGame={{ ...performanceRatingGameData, data: performanceRatingGameBySet }}
        />
      )}
    </Box>
  );
};

export default Performance;
